/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
* CSS Reset
*/
//@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
//@import "./assets/fonts/material-icons-main/css/material-icons.scss";

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:-webkit-any-link {
  text-decoration: none;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

$images-dir: "^images/";

.accordion-title:focus {
  background-color: transparent;
}

/**
* Call out
*/
// call out additional styles to fix close button overlaying text
.callout {
  & > *:first-child:not(.close-button) {
    display: inline-flex;
    margin-right: 1rem;
  }
}

.bundle-qty {
  vertical-align: middle;

  @media not screen and (max-width: 40em) {
    max-width: 4rem;
  }
}

@media screen and (max-width: 58.875em) {
  .hideOnMobile {
    display: none;
  }
}

@-moz-document url-prefix() {
  a,
  p {
    text-decoration: none;
  }
}

.promo-large {
  position: relative;
  overflow: hidden;
  height: 85vh;
  background-position: 34% 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 640px) {
    height: 0;
    padding-top: 41.6667%;
  }

  img.promo-img {
    position: absolute;
    top: 0%;
    height: 100%;

    @media (max-width: 640px) {
      height: 100%;
      max-width: none;
    }
  }

  .promo-border {
    outline: 3px solid #fff;
    outline-offset: -20px;
  }
}

.promo-large-message {
  position: absolute;
  top: 10%;
  left: 10%;
  margin-right: 10%;

  .promo-large-text {
    font-size: 1.625rem;
    /*24px*/
    line-height: 1.4;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-medium {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c6c6c;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  img {
    position: absolute;
    top: 0%;
    left: 0;

    height: 100%;
  }

  @media (min-width: 640px) {
    display: static;
    position: relative;
    height: 0;
    padding-top: 16.6667%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 640px) {
    position: relative;
  }
}

.promo-medium-message {
  color: #fff;

  @media (min-width: 39.9375em) {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
    top: 35%;
  }

  @media (min-width: 63.9375em) {
    font-size: 1.875rem;
    /*30px*/
  }

  > p {
    margin: 0;
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-small-container {
  position: relative;
  width: 100%;

  //In promotion image profile, small rendition has dimension ( 285px by 297px)
  max-width: 285px;
  max-height: 297px;

  .promo-small {
    position: relative;
    height: 0;
    padding-top: 75.1%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 0%;
      display: block;
      height: auto;
      overflow: hidden;
    }

    .promo-border {
      outline: 3px solid black;
      outline-offset: -27px;
    }

    .promo-small-message {
      position: absolute;
      padding: 6px 10px;
      width: 100%;
      font-size: 1.625rem;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      > p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .promo-text-small {
    font-size: 1.375rem;
    /*22px*/
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: black;

    @media (min-width: 900px) {
      font-size: 1.625rem;
      /*26px*/
    }
  }
}

.wch-hero-image {
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: 639px) {
    position: relative;
    height: 85vh;
    overflow: hidden;
  }

  .section {
    /*Heroes touch the header, remove top margin.*/
    padding-top: 0;
    display: flex;
  }

  &.wch-hero-image-message-right .hero-message > div {
    text-align: right;
  }

  &.wch-hero-image-message-left .hero-message > div {
    text-align: left;
  }

  &.wch-hero-image-message-center .hero-message > div {
    text-align: center;
  }

  img {
    max-width: 100%;
    transition: transform 0.3s ease-in;

    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      transform: translate3d(-18%, 0, 0);
      height: 100%;
      max-width: none;
    }

    @media (max-width: 39.9375em) {
      width: auto;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .foreground-image {
    position: absolute;
    z-index: 2;
  }
}

.hero-message {
  top: 0;
  right: auto;
  left: auto;
  /*centering overlaid message*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 -1px 0 rgba(255, 255, 255, 0.5), 1px 0 0 rgba(255, 255, 255, 0.5),
    -1px 0 0 rgba(255, 255, 255, 0.5);

  position: absolute;
  z-index: 2;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 39.9375em) {
    justify-content: flex-start;
    padding-top: 22vh;
  }

  > div {
    padding: 0 1rem;

    @media (min-width: 780px) {
      padding: 0 2rem;
    }

    @media (min-width: 1024px) {
      padding: 0 3rem;
    }
  }

  .text-hero-left {
    text-align: left;
  }

  .text-hero-right {
    text-align: right;
  }

  .text-hero-center {
    text-align: center;
  }
}

h1.text-hero {
  font-size: 2rem;
  line-height: 1;
  color: #000;
  //font-family: Roboto, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  font-weight: 600;

  @media (min-width: 640px) {
    font-size: 2.5rem;
    line-height: 1.26;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;

    h1 {
      max-width: 1200px;
    }

    > .button {
      margin-top: 64px;
    }
  }
}

.text-left {
  text-align: left;
}

/**
* Global mobile fixes
*/

// fix image overlaying border on safari and mobile
img {
  vertical-align: inherit;
}

.swatch-image {
  vertical-align: middle;
}

/* eSpot styling */
.increaseTextContrast {
  .MuiTypography-root {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 30px white;
    display: inline-block;
    border-radius: 6px;
    color: black;
  }
}

.textShadow {
  .MuiTypography-root {
    text-shadow: 0 0 0.3em white;
    color: black;
  }
}

.marketing-heroImage {
  overflow: hidden;
  height: 400px;
  background-size: cover;

  @media (min-width: 800px) {
    height: 500px;
  }

  @media (min-width: 1100px) {
    height: 600px;
  }

  @media (min-width: 1900px) {
    height: 700px;
  }

  .MuiTypography-h2 {
    font-weight: 400;
  }

  .marketing-text {
    left: 20px;

    @media (min-width: 600px) {
      left: 40px;
    }

    @media (min-width: 1000px) {
      left: 60px;
    }
  }

  img {
    opacity: 0.75;

    @media (min-width: 640px) {
      opacity: 0.8;
    }

    @media (min-width: 780px) {
      opacity: 0.85;
    }

    @media (min-width: 900px) {
      opacity: 0.9;
    }
  }
}

.marketing-fullscreen-banner {
  img {
    position: absolute;
  }
}

.HomeHeroContent {
  .marketing-text {
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid white;
    border-radius: 6px;

    @media (max-width: 799px) {
      width: 100%;
      max-width: 100%;
      left: 0;
    }
  }

  .MuiTypography-root {
    display: inline;
  }

  .MuiTypography-h2 {
    font-weight: 700;
  }

  .marketing-text {
    padding: 20px 24px;
    left: 0;

    @media (min-width: 960px) {
      padding: 24px 30px;
      left: 40px;
    }

    @media (min-width: 1320px) {
      left: 0;
    }
  }
}

.FreeDeliveryContent {
  height: 200px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }

  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.4;
  }

  .marketing-text {
    width: 600px;
    max-width: 100%;
    text-align: center;
    padding: 8px 16px;
    font-weight: 500;
    color: black;

    @media (min-width: 600px) {
      padding: 16px 32px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.PercentOffContent {
  border-radius: 6px;
  box-shadow: 2px 3px 3px #ddd;
  margin-top: 16px;
  background: white;

  .MuiGrid-item:last-child {
    min-height: 140px;
    position: relative;
    margin-top: 8px;
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.SapphirePercentOffContent {
  color: white;
  height: 200px;
  border-radius: 6px;
  font-weight: 500;

  @media (min-width: 600px) {
    height: 400px;
  }

  .marketing-text {
    max-width: 350px;
  }
}

.SapphireOrderManagement {
  height: 200px;
  border-radius: 6px;

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }

  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.9;
  }

  .marketing-text {
    width: 600px;
    max-width: 90%;
    padding: 0 16px 8px;
    font-weight: 500;
    color: black;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;

    @media (min-width: 600px) {
      padding: 8px 20px 16px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 400;
  }
}

.marketing-backgroundImage {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
  }
}

.marketing-content {
  position: relative;
  overflow: hidden;
}

.marketing-text {
  &.marketing-text-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.marketing-text-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.marketing-text-horizontal-center.marketing-text-vertical-center {
    transform: translateX(-50%) translateY(-50%);
  }
}

.category-recommendation {
  img {
    display: block;
  }
}

.MuiPopover-root {
  .StyledTableFilterMenu {
    top: 50% !important;
    left: 50% !important;
  }
}

.table-filter {
  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 1.05rem;
  }
}

.bundle-error {
  .MuiTypography-body2.error {
    font-weight: 600;
    font-size: 0.8rem;
  }

  &:last-child {
    float: right;
  }
}

li.MuiBreadcrumbs-li:last-child {
  overflow: hidden;
}

.table-tablet {
  @media screen and (min-width: 40em) and (max-width: 64em) {
    > thead {
      > tr {
        > th {
          padding: 0.2rem;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          padding: 0.2rem;
        }
      }
    }
  }
}

.cprof-cr8-acrdn,
.rl-cr8-acrdn,
.rl-upload-acrdn,
.ip-cr8-acrdn {
  > .MuiAccordionSummary-content {
    > .MuiBox-root {
      flex-wrap: nowrap;
    }
  }
}

.hidden-tooltip {
  display: none;
}

.error-tooltip {
  > div.MuiTooltip-tooltip {
    background-color: white;
    font-size: 0.7rem;
    border-style: solid;
    border-width: 1px;
    border-color: crimson;
    color: crimson;

    > span.MuiTooltip-arrow {
      color: crimson;
    }
  }

  font-size: 2rem;
}

.accordion-icon-text {
  p {
    @media screen and (max-width: 40em) {
      display: none;
    }
  }
}

.shipment-group-summary {
  // panel-text
  > .MuiAccordionSummary-content {
    > .shipment-group-summary-text {
      margin-top: 0.25rem;

      p {
        word-break: break-word;
        /*white-space: normal;*/
      }
    }
  }

  // panel-expander (icon+text)
  > .MuiAccordionSummary-expandIcon.MuiButtonBase-root.MuiIconButton-root {
    margin-top: 0.25rem;

    > .MuiIconButton-label {
      > .accordion-show-summary,
      .accordion-show-expanded {
        @media screen and (max-width: 40em) {
          display: none;
        }
      }
    }
  }
}

p.MuiTypography-root.StyledNumberInput {
  @media screen and (min-width: 40em) {
    max-width: 10rem;
  }
}

.break-word {
  word-break: break-word;
}

.text-right {
  text-align: right;
  justify-content: end;
}

table.inner-borders {
  tr:not(:last-child) {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }

  td:not(:last-child),
  th:not(:last-child) {
    border-right-style: solid;
    border-right-width: thin;
    border-right-color: inherit;
  }
}

// allow the attr drawer table div to wrap text anywhere so that the table doesn't
//   go beyond the visual view-port -- alternative is to track the order-item-table
//   thru a ref and programmatically set max-width on the drawer td (in pixels since
//   percentages don't work) and then allow a scroll on the attr drawer table div
table.order-item-table,
table.list-item-table {
  > tbody > tr > td > div.MuiPaper-root.order-item-table-drawer {
    margin: -8px;

    > table {
      border: 0;

      > thead > tr > th {
        padding: 8px;
      }

      > tbody {
        border: 0;

        > tr {
          border: 0;

          > td {
            padding: 8px;

            // in mobile mode, when displaying attr drawer, give more room at the bottom
            //   for pivoted labels (that are long) to display -- give less room at the
            //   top to create space -- alternative requires programmatically adjusting
            //   the td height thru javascript
            @media screen and (max-width: 40em) {
              padding: 4px 8px 24px;

              > div.tdBefore {
                display: inline-flex;
                position: initial;
                margin-left: calc(-100% - 10px);
                margin-right: calc(50% + 30px);
              }

              > span.td-inline-content {
                display: inline-flex;
              }
            }
          }
        }
      }
    }
  }
}

.dialog--minimal {
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;

    > button.MuiButtonBase-root {
      position: relative;
      padding: 0 0 0 8px;
      top: 0;
      right: 0;
    }
  }

  .MuiDialogContent-root {
    border-top: 0;
    padding-top: 0;

    .MuiDialogActions-root {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row-reverse;
      padding-top: 0;

      > button {
        min-width: 150px;
      }
    }
  }
}

.add-to-wl,
.add-to-rl {
  align-items: flex-end;
}

.button-combo {
  &:first-child {
    justify-content: flex-end;

    .add-to-wl,
    .add-to-rl {
      align-items: flex-end;
    }
  }

  &:not(:first-child) {
    .add-to-wl,
    .add-to-rl {
      align-items: flex-start;
    }
  }
}

// where necessary, adjust pivoted display for table
table.better-pivot {
  @media screen and (max-width: 40em) {
    > tbody > tr > td.pivoted {
      > div.tdBefore {
        display: inline-flex;
        position: initial;
        margin-left: calc(-100% - 10px);
        margin-right: calc(50% + 30px);
      }

      > * {
        display: inline-flex !important;
      }
    }
  }
}

.flex-direction--column {
  flex-direction: column;
}

.width--auto {
  width: auto;
}

@mixin account-welcome-common {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.account-welcome {
  @include account-welcome-common;
  max-width: 155px;
}

.account-welcome-popup {
  @include account-welcome-common;
  max-width: 265px;
}

/*Global color variables*/
$main-color: #000000;
$main-color-hover: #000000;
$main-color-dark: #333333;
$secondary-color: #1c1c1b;
$color-white: #ffffff;
$color-grey: #f7f7f7;
$color-grey-mild: #efefef;
$color-grey-light: #fcfcfc;
$color-grey-dimmer: #e7e7ea;
$color-dark-grey: #707372;
$color-grey-soft: #cccccc;
$color-grey-border: #bbbbbb;
$color-border: #333333;
$color-red: #e91c23;
$color-icon: #003e51;
$color-danger: #e91c23;
$color-success: #008800;
$color-green: #008800;
$border-width: 1px solid;
$border-width-2x: 2px solid;
$font-weight: 700;

a,
.black-text {
  color: $main-color;
}

.text-white {
  color: $color-white;
}

.break-all {
  word-break: break-all;
}

.green {
  color: #008800;
}

.red {
  color: $color-red;
}

.text-title-grey {
  color: $color-dark-grey;
  font-weight: 500;
}

.text-dark-grey {
  color: $color-dark-grey;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.color-grey-dark {
  color: $color-dark-grey;
}

.link-text {
  text-decoration: underline !important;
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid $color-grey-soft;
}

.border-top {
  border-top: 1px solid $color-grey-soft;
}

.text-normal {
  font-weight: normal !important;
}

.display-inline {
  display: inline-block;
}

.capitalize {
  text-transform: capitalize;
}

.grey-text {
  color: $color-grey-border;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 700;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

.red {
  color: $color-red;
}

@mixin boldfont {
  font-weight: 700;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: 700;
}

.flex-itm {
  display: flex;
}

.no-underline {
  text-decoration: none !important;
}

//radio button styling
.custom-radio-button {
  .MuiFormControlLabel-root {
    .Mui-checked {
      color: $main-color;
    }
  }
}

.diveder-pipe::after {
  content: "|";
  color: #000;
  vertical-align: text-bottom;
  position: absolute;
  margin: 0 5px;
}

.bv-write-review-container .bv-write-review:after {
  content: "|";
  color: #000;
  vertical-align: text-bottom;
  position: absolute;
  margin: 0 0px 0px 10px;
}

/*Google icons*/

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@mixin material-icon-include {
  font-family: "Material Icons Outlined";
  display: inline-block;
  line-height: 1;
  letter-spacing: normal;
  word-wrap: normal;
  direction: ltr;
  white-space: nowrap;
  font-feature-settings: "liga";
}

@mixin material-icon-filled-include {
  font-family: "Material Icons";
  display: inline-block;
  line-height: 1;
  letter-spacing: normal;
  word-wrap: normal;
  direction: ltr;
  white-space: nowrap;
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMlhLzTs.woff2) format('woff2');
  src: url(./assets/fonts/JosefinSans-Regular.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMhhLzTs.woff2) format('woff2');
  src: url(./assets/fonts/JosefinSans-Regular.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  //src: url(https://fonts.gstatic.com/s/josefinsans/v25/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMZhLw.woff2) format('woff2');
  src: url(./assets/fonts/JosefinSans-Regular.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin fontfamily {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
}

div.app-main-wrapper {
  -webkit-perspective: none;
  perspective: none;
  height: auto;

  .header-wrapper-top {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;

    .header-bg-new {
      background: $main-color;
      height: 5rem;

      .header-branding {
        img {
          height: 30px;
        }
      }

      .expanded-menu-container {
        z-index: 1;
        background-color: #fff;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.45);
        padding: 9px 0;
        height: 69px;

        .expanded-menu,
        .expanded-menu-hover {
          &::after {
            @include material-icon-include;
            content: "\e5cf";
          }

          &::after {
            content: "|";

            color: #ccc;
            font-size: 25px;
            position: absolute;
            top: 16px;
          }

          background-color: #fff;

          .expanded-menu-text {
            color: #000;
          }

          .expanded-menu-text.blue-text {
            color: #0045ab;
          }

          .vertical-padding-2 {
            padding-bottom: 3px;
          }
        }

        .expanded-menu#sales-menu,
        .expanded-menu-hover#sales-menu {
          &::after {
            display: none;
          }
        }

        .expanded-menu-hover {
          pointer-events: none;

          .MuiButton-label {
            border-bottom: 3px solid #000;
            padding-bottom: 8px;
          }

          .expanded-menu-text {
            color: $color-danger;
          }

          .expanded-menu-text.blue-text {
            color: #0045ab;
          }
        }

        //mega menu
        .megaMenuWpr {
          width: 100%;
          right: 0px;
          left: 0px !important;
          top: 149px !important;
          border-top: 1px solid #ccc;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px 2px, rgba(0, 0, 0, 0.03) 0px 2px 1px 0px;

          .expanded-menu-Whpr {
            min-height: 200px;
            display: flex;
          }

          .expandedMainMenu {
            position: relative;
            margin: 10px auto;
          }

          .threeMenuWpr {
            border-right: 1px solid #cccc;
            padding-left: 2.5%;

            .menu-header-column {
              padding-bottom: 2rem;

              .subMenuHeader {
                width: 100%;
                margin-top: 0px;

                .subMenuHeaderTierOne {
                  width: 100%;
                  display: flex;
                }

                .material-icons-outlined.primary-color {
                  color: $color-icon;
                }

                .material-icons-outlined.primary-color {
                  color: #bbb;
                }
              }

              .subMenuHeaderVal {
                list-style: none;

                li {
                  font-size: 1.1em;
                  cursor: pointer;

                  &:hover {
                    a {
                      P {
                        color: $color-red;
                      }
                    }
                  }
                }

                li:hover {
                  color: $color-red;
                }
              }
            }
          }

          .subMenuHeaderTiertwo.active {
            a {
              P {
                color: $color-red;
              }
            }
          }

          .threeMenuWpr:last-child {
            border-right: 0px;
          }

          .menu-product-widget {
            width: 43%;
            //min-height: 430px;
            padding-left: 2rem;

            h4 {
              display: none;
            }
          }
        }

        .expanded-menu-sub-links {
          margin-bottom: 6px;
          margin-top: 6px;
          text-transform: uppercase;
          font-size: 0.8rem;
          padding-right: 10px;
        }

        //mega menu end
      }

      .material-icons-outlined {
        color: $color-white;
      }

      .expanded-menu-bold {
        text-transform: uppercase;
        font-size: 1rem;
      }

      .twoTierHeading {
        font-weight: 500;
      }
    }

    .cart-count {
      position: relative;

      .count-bg {
        position: absolute;
        top: 7px;
        right: 2px;

        span {
          background: $color-white;
          vertical-align: middle;
          padding: 2px 4px 0 4px;
          line-height: 0;
          font-size: 0.7rem;
          font-weight: $font-weight;
          border-radius: 2px;
        }
      }
    }

    .expanded-menu-text {
      font-size: 1.1rem;
    }

    .external-site-link {
      padding: 5px 2px 0px 5px;
      border: $border-width $color-white;
      border-radius: 0.3rem;

      span {
        font-size: 1rem;
        padding-left: 0.3rem;
      }

      a {
        color: $color-white;
        display: flex;
        text-decoration: none;
      }
    }

    //search bar
    .search-desktop-container {
      .search-toggle-box {
        position: absolute;
        right: 0;
        top: 5rem;
        background: $color-white;
        width: 100%;
        min-height: 10rem;
        z-index: 5;
        padding-bottom: 2rem;
        overflow: auto;
        max-height: 85vh;
      }

      .header-search-bar {
        text-align: center;

        .search-input.MuiFormControl-root {
          width: 50%;
        }

        .search-input {
          input[type="text"] {
            background-color: $color-grey;
            padding-left: 3rem;
            font-size: 1.3rem;
          }

          input[type="text"],
          fieldset {
            border-radius: 2rem;
            border-width: 0px;
          }

          .input-close {
            margin-left: 0;
            position: absolute;
            right: 0;
          }
        }

        .search-icon {
          color: $main-color;
          position: absolute;
          z-index: 1;
          top: 0.5rem;
          margin-left: 1rem;
        }

        .result-list {
          display: flex;
          justify-content: center;
          margin: auto;
          padding-top: 1.2rem;

          .searchBar-results {
            width: 50%;
            text-align: left;

            h6 {
              color: $color-grey-border;
              text-transform: capitalize;
            }
          }

          .suggestion-container {
            width: 50%;
            text-align: left;

            .searchBar-resultsCategory {
              color: #bbbbbb;
              text-transform: capitalize;
              text-align: left;
            }

            .shop-all {
              margin-left: 1.3rem;
              margin-top: 0.8rem;
            }

            .suggestion-product {
              align-items: center;

              .sugg-img-product {
                width: 15%;

                img {
                  padding: 0.5rem;
                }
              }

              .sugg-prod-info {
                width: 85%;

                .brndNmeTxt {
                  padding-top: 0px;
                  padding-bottom: 2px;
                }

                .brand {
                  img {
                    width: 20px;
                  }
                }

                .product-price {
                  color: $color-green;
                }
              }
            }
          }
        }
      }

      .search-modal-close {
        position: absolute;
        right: 2px;
        bottom: -46px;
        z-index: 10;
        background: $color-grey-mild;
        padding: 0.3rem;
        line-height: normal;
        cursor: pointer;

        span {
          font-size: 2rem;
          color: $main-color;
        }
      }

      .search-overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: -1;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
      }
    }
  }

  .search-suggestion-display {
    width: 50%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .search-suggested-words {
    width: 122%;
    text-align: center;
    position: relative;
    left: -21%;
    z-index: 1;
    background: $color-white;
    top: 1rem;
  }

  .search-suggested-words,
  .search-suggested {
    h6,
    h5 {
      text-transform: inherit;
      font-weight: normal;
    }
  }

  .search-did-mean {
    display: flex;
    justify-content: center;

    .icon {
      font-size: 1rem;
      margin-right: 0.3rem;
    }
  }

  .searh-keyword-hints {
    span {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    .icon {
      margin-left: 0rem;
      margin-right: 0.5rem;
      font-size: 0.7rem;
      color: $color-grey-border;
    }
  }

  .content-wrapper-all {
    margin-top: 10.2rem;
  }

  .MuiAccordion-root {
    &::before {
      background: $color-grey;
    }
  }
}

//footer
.footer-wrapper {
  .footer-second {
    background-color: $main-color-dark;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .foot-icons {
    font-size: 2.5rem;
    color: $color-grey-mild;
  }

  p,
  div.footer-nav a,
  div.footer-nav a:visited,
  div.contact-us a,
  div.contact-us a:visited,
  small {
    color: $color-grey-mild;
  }

  .footer-info,
  .footer-signup {
    p {
      margin: 0;
    }
  }

  .footer-top {
    justify-content: center;
  }

  .footer-info {
    padding: 1.5rem 0;
  }
}

.flex {
  display: flex;
}

.align-end {
  align-items: end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.text-right {
  text-align: right;
}

.border-main {
  border: $border-width $main-color;
  margin: 10px 0;
}

.close-position {
  position: absolute;
  right: 5px;
}

.top-5 {
  top: 5px;
}

.product-price {
  color: $color-green;
}

.padding-ty {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-15 {
  padding: 14px;
}

.z-index-2 {
  z-index: 2;
}

.padding-ty-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-0 {
  padding: 0px;
}

.padding-5 {
  padding-top: 5px;
}

.padding-5p {
  padding: 5px;
}

.font-size-13 {
  font-size: 13px !important;
}

.margin-ty {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-4 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.margin-0 {
  margin: 0px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-8 {
  padding-right: 8px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-7 {
  margin-bottom: 7px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.cart-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  height: auto;
}

.border-right-10 {
  border-right: $border-width $main-color;
  padding-right: 10px;
}

.border-right-15 {
  border-right: $border-width $main-color;
  padding-right: 15px;
}

.border-bottom-grey {
  border-bottom: $border-width $color-grey-soft;
}

a.underline,
a:-webkit-any-link.underline {
  text-decoration: underline !important;
}

.margin-bottom {
  margin-bottom: 15px;
}

.margin-ty {
  margin-top: 15px;
  margin-bottom: 15px;
}

.flex-direction--column {
  flex-direction: column;
}

.baseline {
  align-items: baseline;
}

.white-box {
  background-color: $color-white;
  border: 1px solid $color-grey-soft;
  padding: 5px;

  button {
    border: 0px;
    background-color: $color-white;
    display: flex;
    align-items: center;
    font-family: Josefin Sans, Arial, sans-serif;
    cursor: pointer;
  }
}

.centerline {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.strikethrough {
  text-decoration: line-through;
}

input:focus {
  outline: none;
}

.font-bold {
  font-weight: bold;
}

.relative {
  position: relative;
}

.width-full {
  width: 100%;
}

.input-Style {
  height: 44px;
  width: 100%;
}

.input-Wrapperr {
  position: relative;
}

.input-arrow {
  position: absolute;
  right: 5px;
  top: 9px;
}

.white-text {
  color: $color-white;
}

.black-btn {
  background-color: $main-color;
  border: 1px solid $color-white;
  padding: 8px 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-Style.black {
  background-color: $main-color;
  border-color: transparent;
}

input:-webkit-autofill {
  -webkit-text-fill-color: $main-color;
}

input:-webkit-autofill:focus,
input:-internal-autofill-selected {
  -webkit-text-fill-color: $main-color;
}

.MuiButtonBase-root.MuiButton-containedPrimary {
  &::after {
    @include material-icon-include;
    content: "\f1df";
    padding-left: 15px;
  }
}

.MuiButtonBase-root.MuiButton-containedSecondary,
.MuiAccordionSummary-expandIcon {
  &::after {
    @include material-icon-include;
    content: "\f1df";
    padding-left: 15px;
  }
}

.MuiAccordionSummary-expandIcon {
  &::after {
    content: "\e145";
    padding-left: 0;
  }

  .MuiIconButton-label {
    display: none;
  }
}

.MuiAccordionSummary-expandIcon.Mui-expanded {
  &::after {
    content: "\e15b";
  }
}

// Review Rating css
.bv-cv2-cleanslate .bv-core-container-200 .bv-inline-rating-container .bv-rating-stars-on {
  color: $main-color !important;
}

.bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-stars-on,
.bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-stars-off {
  font-size: 18px !important;
}

.bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-label {
  font-family: "Josefin Sans" !important;
  font-size: 0.9rem;
}

/*PLP page desktop*/
.ribbonImage {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.plp-breadcrum {
  nav {
    margin-bottom: 0;
    margin-top: 2px;
  }

  ol {
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1rem;
    }

    // margin-bottom: 2rem;
  }
}

.plp-quick-filter-wrap {
  margin: 1rem 1rem;
  border: solid $color-grey-dimmer;
  border-width: 1px 0;
  padding: 1rem 0;
}

.plp-quick-filter,
.plp-new-arrivals {
  display: flex;
  justify-content: center;

  .product-container {
    margin-right: 5%;
  }

  .product-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-img {
    border-radius: 8px;
    padding: 5px;
    border: $border-width $color-grey-mild;

    img {
      width: 100%;
    }

    &.active {
      border: $border-width $main-color;
    }
  }

  .product-category {
    text-align: center;
    text-decoration: underline;
    padding: 4px;
    color: $main-color;
  }
}

.plp-new-arrivals {
  flex-wrap: wrap;
  justify-content: flex-start;
  background: $color-grey-light;
  padding: 0 15px 15px 15px;

  .product-container {
    width: 15%;
    margin-right: 2%;
  }

  .product-img {
    border-radius: 0;
  }

  .title {
    flex-basis: 100%;
  }
}

.plp-sidebar {
  .checkbox-filter {
    .MuiButtonBase-root {
      display: none;
    }
  }

  .plp-filter-wrapper {
    .MuiAccordionSummary-root {
      background: $color-grey;

      .MuiAccordionSummary-content {
        text-transform: uppercase;
      }
    }

    .MuiFormControlLabel-root.checkbox-filter {
      border-bottom: $border-width $color-grey-dimmer;
      padding: 10px 0;
      padding-left: 0.5rem;

      &:hover {
        background-color: $main-color-hover;
        color: $color-white;
      }
    }

    .plp-product-filter {
      box-shadow: none;
    }
  }
}

.plp-facet-wrap {
  .color-swatches {
    display: flex;
    margin-bottom: 1rem;
    margin-bottom: 0.5rem;
    flex-direction: column;
    flex: 0 0 32%;
    align-items: center;
  }

  .show-more-less {
    margin-top: 1.5rem;
    display: flex;
    flex: 0 0 100%;
  }

  .go-link {
    display: inline-flex;
    align-items: center;

    .more,
    .less {
      font-size: 0.8rem;
      margin-left: 0.3rem;
    }
  }
}

//Product Image
.product-image-wrap {
  .product-card {
    box-shadow: none;
    border-radius: 0;

    .product-card-image {
      overflow: hidden;

      // .thumbnail-image {
      //   mix-blend-mode: multiply;
      // }
    }

    .product-info-section,
    .product-wishlist {
      display: none;
    }

    .product-price {
      color: $color-green;
    }

    .thumb-image-wrap {
      //   background: $color-grey;
      border: $border-width $color-grey-dimmer;
    }

    .product-brand {
      display: flex;

      .brand {
        min-width: 25px;
        margin-right: 5px;
      }
    }

    .price-styleid {
      display: flex;
    }

    .rating-styleid {
      display: flex;
      justify-content: space-between;
      min-height: 26px;
    }

    .price-styleid,
    .product-brand {
      .MuiTypography-body2 {
        width: auto !important;
        margin: initial !important;
      }

      // .product-price-section {
      //   margin-left: 10px;
      // }
    }

    .product-styleId,
    .product-name {
      width: auto !important;
      margin: initial !important;
    }

    .product-styleId {
      margin-right: 7px;
    }

    .brand {
      img {
        width: 24px;
      }
    }
  }
}

// PLP page desktop end

//PDP page
.pdp-page-wrap {
  margin-top: 1rem;
}

.shipping-box {
  display: flex;

  .ship-text {
    padding-left: 5px;
  }
}

.pdp-page-container {
  box-shadow: none !important;
  padding: 0.1rem !important;

  #product-img-height {
    li {
      list-style: none;
    }
  }

  .ribbonImage {
    z-index: 1;
  }

  .brand {
    display: flex;
    align-items: baseline;

    .brndNmeTxt {
      color: $color-grey-border;
      margin-left: 0.6rem;
    }
  }

  .share-wishlist {
    display: flex;
    position: relative;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        &:hover::before {
          @include material-icon-filled-include;
          content: "\e87d";
          padding-left: 0px;
          position: absolute;
        }
      }
    }

    .wish-section {
      border: 1px solid $color-grey-soft;
      padding: 0.5rem;
      margin-left: 1rem;
    }
  }

  .product-price {
    color: $color-green;
  }

  .style-price-sec {
    display: flex;
  }

  .product-style {
    color: $color-dark-grey;
  }

  .product-price-container {
    margin-bottom: 0 !important;
  }

  .size-measurement {
    display: inline;
    float: right;
  }

  .asgswatchsize,
  fieldset.Size {
    .MuiFormGroup-root {
      flex-direction: row;
    }

    .MuiButtonBase-root {
      .MuiIconButton-label {
        display: none;
      }
    }

    .MuiButtonBase-root + .MuiFormControlLabel-label {
      border: $border-width $color-grey-soft;
      padding: 3px;
      min-width: 45px;
      text-align: center;
    }

    .MuiButtonBase-root + .Mui-disabled {
      border-color: $color-grey-dimmer;
    }

    .MuiIconButton-root.Mui-checked + .MuiFormControlLabel-label {
      border: $border-width-2x $main-color;
    }
  }

  .size-measurement {
    font-weight: normal;
  }

  .qty-cart-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .product-quantity {
      flex: 1 0 100%;
    }

    .react-numeric-input {
      input {
        font-size: 16px;
      }
    }

    .product-add-to-cart,
    .product-buy-now {
      margin: 0;
      width: auto;
      margin-left: 1rem;
    }
  }

  .select-offer {
    span {
      color: $color-red;
    }

    .title {
      font-size: 0.85rem;
      font-weight: 700;
    }
  }

  .product-desc-sec {
    ul {
      margin: 1rem 0 1rem 1rem;
    }
  }

  .pdp-details-accordin {
    border-bottom: 1px solid $color-grey-dimmer;
    box-shadow: none;

    .MuiAccordionSummary-content {
      h6 {
        text-transform: capitalize;
      }
    }

    .MuiAccordionSummary-root {
      border-bottom: 1px solid $color-grey-dimmer;
      padding-left: 0px;
      pointer-events: none;
    }

    .MuiCollapse-hidden {
      display: none;
    }

    .MuiAccordionSummary-expandIcon {
      display: none;
    }
  }

  .product-slider {
    > div {
      padding-bottom: 360% !important;
    }

    .slide-carousel {
      .thumbnail {
        width: 50%;
      }
    }
  }

  .sticky-pdp-thumb {
    position: sticky;
    top: 150px;
    max-height: 100vh;
  }

  .pdp-thumbnail-slider {
    button {
      border: $border-width $main-color;
      margin: 0.5rem 0.5rem 0 0.5rem;

      &:hover {
        border: $border-width $main-color;
      }
    }
  }

  .product-image {
    .image-wrap {
      /*background-color: $color-grey;*/
      margin-right: 3rem;

      // img {
      //   mix-blend-mode: multiply;
      // }
      img {
        max-height: 680px;
      }
    }
  }
}

.quick-view-modal {
  .quick-view-content {
    border-top: 0;
    margin-bottom: 1rem;
    min-height: 160px;
  }

  .sticky-pdp-thumb {
    position: static;
  }

  .product-image {
    .image-wrap {
      margin-right: 0;
      padding-bottom: 15px;

      img {
        max-height: 400px;
      }
    }
  }

  .style-price-sec {
    margin-top: 0.8rem;

    .divider {
      margin: 0 0.5rem 0 0.5rem;
    }
  }

  .size-measure {
    margin-left: 1rem;
  }

  .view-details {
    margin-top: 1rem;
  }

  .asgswatchcolor.swatch-group {
    .swatch-tooltip {
      flex: 0 0 45px;
    }

    .swatch-sub-wpr {
      width: 45px;
      height: 50px;
      border: $border-width $color-grey-soft;
      padding: 2px 0;
    }

    .swatches-button {
      width: 100%;
      height: 100%;
    }
  }

  .pdp-rating-top {
    margin-top: 0.6rem;
  }

  #BVRRSummaryContainer {
    min-height: 22px;
  }

  .bv-cv2-cleanslate .bv-core-container-211 .bv-summary-bar-minimalist-horizontal .bv-write-container {
    display: none !important;
  }
}

.Color.swatch-group,
.asgswatchcolor.swatch-group {
  .swatch-tooltip {
    flex: 0 0 85px;
  }
  .swatch-tooltip.count0,
  .swatch-tooltip.countNaN {
    .swatch-sub-wpr {
      opacity: 0.3;
      position: relative;
      pointer-events: none;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $main-color; /* Adjust the color as needed */
        transform: translateY(-50%);
        transform: rotateZ(-50deg);
        z-index: 1;
      }
    }
  }
  .swatch-sub-wpr {
    width: 80px;
    height: 85px;
    border: $border-width $color-grey-soft;
    padding: 4px 0;
  }

  .swatches-button {
    background-position: center;
    background-size: contain;
    border-radius: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background-repeat: no-repeat;
    background-color: $color-white;
    border: 0px;

    svg {
      display: none;
    }
  }

  .swatch-sub-wpr.selected {
    border: $border-width-2x $main-color;
  }
}

.StyledNumberInput.mobile {
  .react-numeric-input {
    b {
      top: 0px;
      bottom: 0px;
      right: 1px;

      &:last-child {
        left: 1px;
        right: inherit;
      }
    }
  }
}

//Review rating
#BVRRSummaryContainer {
  min-height: 40px;

  .bv-cv2-cleanslate .bv-core-container-200 .bv-primarySummary-rating-container .bv-rating-stars-on {
    color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-summary-bar .bv-rating.bv-text-link {
    color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-text-link {
    color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 button.bv-write-review,
  .bv-cv2-cleanslate .bv-core-container-200 button.bv-read-review,
  .bv-cv2-cleanslate .bv-core-container-200 button.bv-ask-question,
  .bv-cv2-cleanslate .bv-core-container-200 .bv-button {
    background-color: transparent !important;
    color: $main-color !important;
    padding-left: 0 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .bv-action-bar {
    padding: 0 !important;
  }

  // .bv-details-bar {
  //   display: none !important;
  // }

  // .bv-cv2-cleanslate div {
  //   display: flex !important;
  // }
}

#BVRRSearchContainer {
  .bv-cv2-cleanslate
    .bv-core-container-200
    .bv-content-search
    .bv-masthead
    .bv-masthead-product
    .bv-stars-container
    .bv-rating-stars-on {
    color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-text-link {
    color: $main-color !important;
  }
}

#BVRRContainer {
  .bv-cv2-cleanslate .bv-core-container-200 .bv-action-bar button {
    background-color: transparent !important;
    color: $main-color !important;
    padding-left: 0 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .bv-action-bar-header {
    font-size: 1.2rem !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-content-secondary-ratings-value,
  .bv-cv2-cleanslate .bv-core-container-200 .bv-secondary-rating-summary-value {
    background-color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-rating-stars-on,
  .bv-cv2-cleanslate .bv-core-container-200 .bv-secondary-rating-summary-value {
    color: $main-color !important;
  }

  .bv-cv2-cleanslate .bv-core-container-200 .bv-author.bv-fullprofile-popup-target {
    color: $main-color !important;
  }
}

.bv-cv2-cleanslate
  .bv-core-container-200
  .bv-dropdown-container
  .bv-dropdown
  ul.bv-dropdown-active
  .bv-dropdown-item:hover,
.bv-cv2-cleanslate .bv-core-container-200 .bv-submission .bv-fieldsets .bv-submit,
.bv-cv2-cleanslate
  .bv-core-container-200
  .bv-submission
  .bv-fieldsets
  .bv-form-actions
  .bv-submission-button-submit:hover {
  background-color: $main-color !important;
}

//product carousel
.product-widget-carousel {
  .carousel,
  .static-product-carousel {
    height: auto;

    .product-image-wrap {
      margin-top: 1rem;
      //margin-left: 1rem;
      height: auto;
    }

    .product-image-wrap-inner {
      &:hover {
        transform: none;
      }

      &:hover .thumbnail-image {
        transform: none;
      }

      .product-card {
        box-shadow: none;
        max-height: 100%;

        .product-footer {
          text-align: left;

          span {
            margin-right: 10px;
          }

          .strikethrough {
            text-decoration: line-through;
          }
        }
      }

      .plp-swatch-carousel {
        .show-more {
          margin-left: 0;
        }
      }
    }
  }

  .carousel {
    .product-card {
      max-width: 100%;
    }
  }

  .static-product-carousel {
    .product-image-wrap-inner {
      &:hover {
        position: static;
      }

      .product-card {
        margin-right: 0;
        max-width: 300px;
      }
    }
  }
}

//carousel quick view page
div.quick-view-modal {
  .product-widget-carousel {
    .product-image-wrap-inner {
      .thumb-image-wrap {
        img {
          max-width: 100%;
        }
      }

      .product-footer {
        margin-top: 8px;
      }

      .product-brand {
        margin-bottom: 8px;
      }

      .plp-swatch-carousel {
        .swatch-slider {
          img {
            max-width: 100%;
          }
        }

        .swatch-slide {
          margin-right: 1px;
        }
      }

      .product-full {
        padding: 0 8px;
      }
    }

    .static-product-carousel {
      .product-image-wrap-inner {
        .product-card {
          max-width: 260px;
        }
      }
    }
  }
}

//pdp end
//checkout page
.checkout-header {
  .secure-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 25px;
  }
}

.order-details-btn {
  margin: auto;
  margin-bottom: 10px;
}

.custom-radio-button,
[role="radiogroup"] {
  .radio-label,
  .MuiFormControlLabel-root {
    .Mui-checked {
      div {
        svg:nth-child(2) {
          transform: scale(0);
        }

        &::after {
          @include material-icon-filled-include;
          content: "\e86c";
          padding-left: 0px;
          position: absolute;
          font-size: 24px;
        }
      }
    }
  }
}

.order-history-detailspage {
  .MuiDialog-paperScrollPaper {
    box-sizing: border-box;
    max-width: 70%;
    padding: 30px;
    width: 70%;
  }

  .order-steps {
    .MuiStepLabel-label {
      @include fontfamily;
    }
  }
}

#CheckOutPage {
  .MuiAccordion-root {
    .MuiAccordionDetails-root {
      padding: 0;
    }
  }

  .checkout-leftside {
    .MuiAccordionSummary-root {
      border-bottom: $border-width;
    }

    .MuiAccordion-root {
      box-shadow: none;

      .MuiPaper-root {
        box-shadow: none;
      }
    }
  }

  .checkout-rightside {
    .MuiAccordionSummary-root.Mui-expanded {
      border-bottom: $border-width $color-grey-dimmer;
    }
  }
}

.accordin-likebox {
  .title-section {
    display: flex;
    justify-content: space-between;
    border-bottom: $border-width $main-color;
  }
}

// cart-whishlist
.cart-wishlist {
  .addremoveaction {
    justify-content: left;

    .delete-overlay {
      background-color: rgba(255, 255, 255, 0.8);
    }

    .cardactionremove {
      margin-left: 90px;

      .MuiButton-outlinedPrimary::after {
        @include material-icon-include;
        content: "\f1df";
        padding-left: 15px;
      }

      .MuiButton-outlinedPrimary {
        border: $border-width $main-color;
      }

      .MuiButton-textPrimary > span > p {
        display: none;
      }

      > div:last-child > .MuiButton-textPrimary ::before {
        @include material-icon-include;
        content: "\e92e";
        border: $border-width $main-color;
        padding: 6px;
        font-weight: normal;
        font-size: 1.4rem;
      }
    }
  }

  .mobile-WishList {
    .addremoveaction {
      justify-content: center;
      margin-left: 0px;
    }
  }

  .divider {
    display: none;
  }

  .wishlistDesign {
    display: flex;
    flex-direction: row;
  }

  .wishlistimage {
    padding-right: 5px;
  }

  .whishlistdata {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .WhishListData {
    padding-bottom: 6px;
    padding-top: 6px;

    .whishlistData {
      font-size: 14px;
      color: $color-grey-border;
    }
  }

  .wishlistDesign {
    .underline {
      font-size: 14px;
      padding-right: 5px;
    }
  }

  .whishlistpad {
    padding-left: 0px;
    padding-right: 0px;
  }

  .wishlist-card {
    box-shadow: none;
    border-radius: 0;
    border: none;
  }

  .mobile-stylecard {
    justify-content: space-evenly;
    flex-wrap: nowrap;

    .cartwishlistData {
      margin-top: 1rem;
    }

    div > .wishlistimage .image {
      width: 5rem;
      height: 6rem;
      margin: 1rem;
    }
  }

  .wishliststyledcard {
    padding-left: 0px;
    padding-right: 0px;

    .stylecard {
      flex-wrap: nowrap;

      .wishlist-image {
        .image {
          width: 100px;
          height: 100px;
        }
      }
    }

    [style="position: absolute;"] {
      display: none;
    }
  }

  .cartwishlistdata {
    margin: 1.25rem 0.62rem 0.62rem;
  }

  @media (max-width: 375px) {
    .mobile-WishList {
      .wishliststyledcard {
        .mobile-stylecard {
          justify-content: space-evenly;

          div > .wishlistimage .image {
            width: 5rem;
            height: 6rem;
          }
        }
      }
    }
  }

  @media (max-width: 1366px) {
    .WishList {
      .wishliststyledcard {
        padding: 10px 0px;

        .stylecard {
          justify-content: start;
        }

        .image {
          margin-top: 1rem;
        }

        .cartwishlistdata {
          margin: 1.25rem 0 1.25rem;

          p {
            font-size: 0.9rem;
          }

          .whishlistdata {
            font-size: 0.9rem;
          }

          .WhishListData {
            .whishlistData {
              font-size: 0.9rem;
            }
          }

          .wishlistDesign {
            .underline {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .WishList {
      .wishlist-card {
        .MuiGrid-direction-xs-column {
          .MuiGrid-grid-xs-true {
            .wishliststyledcard {
              .stylecard {
                .cartwishlistdata {
                  margin: 1.25rem 0rem 1.25rem;

                  .whishlistdata {
                    font-size: 0.9rem;
                  }

                  .WhishListData {
                    .whishlistData {
                      font-size: 0.9rem;
                    }
                  }

                  .wishlistDesign {
                    .underline {
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
          }
        }

        .addremoveaction > .cardactionremove > div:first-child > .MuiButton-outlinedPrimary > span > p {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .WishList {
      .wishlist-card {
        .MuiGrid-direction-xs-column {
          .MuiGrid-item {
            .wishliststyledcard {
              .image {
                margin-top: 1.3rem;
              }

              .cartwishlistdata {
                margin: 20px 0px 20px;

                .WhishListData {
                  .whishlistData {
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 414px) {
    .mobile-WishList {
      .wishlist-card {
        .product-full {
          .mobile-stylecard {
            div > .wishlistimage .image {
              width: 5rem;
              height: 6rem;
            }
          }
        }
      }
    }
  }
}

.wishlist-tabDesign {
  border: $border-width $color-grey-dimmer;
  margin-top: 2rem;

  .customLabel {
    font-size: 1rem;
    color: $main-color;
  }

  .tabBg {
    background-color: $color-white;
    box-shadow: none;
    border-bottom: $border-width-2x $color-grey-dimmer;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
  }
}

// cart-whishlist end

// myaddress start
.sidebar-address-left {
  .MuiPaper-root {
    box-shadow: none;
  }

  .address-breadcrum {
    display: flex;

    .arrow-ico {
      font-size: 1rem;
    }
  }
}

.myaddress-right {
  .MuiGrid-spacing-xs-2 {
    padding-top: 1rem;
  }

  .address-right-bg {
    background: $color-grey;
    padding: 1rem;

    .address-card {
      padding: 0.7rem;
    }

    .address-type {
      border-top: $border-width $color-grey-border;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
    }

    .cardactionremove {
      padding-left: 8px;
      padding-bottom: 0;
    }
  }
}

@media (max-width: 375px) {
  .myaddress-right {
    .MuiGrid-spacing-xs-2 {
      .MuiGrid-root > h3 {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 959px) {
  .sidebar-address-left .MuiAppBar-positionFixed {
    position: relative;
  }

  .pdp-thumbnail-slider {
    .carousel__dot-group {
      display: none;
    }

    .carousel__slider-tray-wrapper.carousel__slider-tray-wrap--horizontal {
      padding-bottom: 0px !important;
    }

    .carousel__inner-slide {
      img {
        max-height: 300px;
      }
    }
  }

  .pdp-top-section {
    padding-top: 1.3rem;
  }

  .Color.swatch-group .swatch-tooltip,
  .asgswatchcolor.swatch-group .swatch-tooltip {
    max-width: 23%;
    flex-basis: 23%;
    text-align: center;
  }

  .order-track-stepper {
    .MuiStep-horizontal,
    .MuiStepConnector-line {
      display: none;
    }

    .Mui-completed:last-of-type {
      display: block;
    }

    .Mui-completed > .MuiStep-horizontal {
      display: block;
    }
  }

  .order-track-stepper.new0step {
    .MuiStep-horizontal:nth-child(1) {
      display: block;
    }
  }

  .order-track-stepper.new1step {
    .MuiStep-horizontal:nth-child(2) {
      display: block;
    }
  }

  .order-track-stepper.new2step {
    .MuiStep-horizontal:nth-child(3) {
      display: block;
    }
  }

  .order-track-stepper.new3step {
    .MuiStep-horizontal:nth-child(4) {
      display: block;
    }
  }

  .order-track-stepper.new4step {
    .MuiStep-horizontal:nth-child(4) {
      display: block;
    }
  }

  .order-dtls-hdng {
    margin-top: 3rem;
  }

  .mobile-left-null {
    margin-left: 0px;
  }
}

// myaddress end

// add address start

.sidebar-addaddress-left {
  .MuiPaper-root {
    box-shadow: none;
  }

  .addaddress-breadcrum {
    display: flex;

    .arrow-ico {
      font-size: 1rem;
    }
  }
}

.addressForm {
  .emailAddress {
    .email-msg {
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: $color-dark-grey;
    }
  }

  span.req {
    color: $color-danger;
    font-size: 1.2rem;
    line-height: 0;
  }

  .edit-state {
    fieldset {
      &::after {
        @include material-icon-include;
        content: "\e5c5";
        position: absolute;
        right: 0px;
        font-size: 1.6rem;
      }
    }
  }
}

.addaddress-right-section {
  .addressForm > .address-type > fieldset > div {
    display: flex;
    flex-direction: row;
  }

  .addAddress {
    margin-top: 1rem;
  }

  .addressLabel {
    .Mui-checked {
      div::after {
        font-size: inherit;
      }
    }
  }

  .addressForm {
    input {
      background-color: $color-white;
    }

    .addressLabel > label > .MuiFormControlLabel-label {
      margin-top: 3px;
    }

    .MuiCheckbox-colorPrimary {
      margin-bottom: 5px;
    }
  }

  .edit-country > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    background-color: $color-white;
  }

  .edit-state > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    background-color: $color-white;
  }

  .addAddress {
    margin-top: 1rem;
  }
}

.addaddress-right-bg {
  background-color: $color-grey;
  border-radius: 1rem;

  > div:last-child {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.address-card {
  .addremoveaction {
    .cardactionremove :last-child > button {
      text-decoration: underline;

      &::before {
        content: "\007c";
        padding-right: 1rem;
      }
    }
  }
}

@media (min-width: 1499px) {
  .addaddress-right-bg {
    padding-top: 1.9rem;
    padding-left: 3.1rem;
    padding-bottom: 1.25px;
  }

  .addaddress-right-bg .addaddress-right-section {
    padding-right: 18rem;
  }

  .addaddress-right-bg > div:last-child {
    .MuiButton-containedPrimary {
      margin-right: 17.7rem;
    }
  }
}

// add address end

// edit address start//

.sidebar-editaddress-left {
  .MuiPaper-root {
    box-shadow: none;
  }

  .editaddress-breadcrum {
    display: flex;

    .arrow-ico {
      font-size: 1rem;
    }
  }
}

.editaddress-right-section {
  .addressForm > .address-type > fieldset > div {
    display: flex;
    flex-direction: row;
  }

  .editAddress {
    margin-top: 1rem;
  }

  .addressForm {
    input {
      background-color: $color-white;
    }

    .addressLabel > label > .MuiFormControlLabel-label {
      margin-top: 3px;
    }

    .MuiCheckbox-colorPrimary {
      margin-bottom: 5px;
    }
  }

  .edit-country > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    background-color: $color-white;
  }

  .edit-state > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    background-color: $color-white;
  }

  .editAddress {
    margin-top: 1rem;
  }

  .addressLabel {
    .MuiFormControlLabel-root .Mui-checked div::after {
      font-size: 26px;
    }
  }
}

.editaddress-right-bg {
  background-color: $color-grey;
  border-radius: 1rem;

  > div:last-child {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1499px) {
  .editaddress-right-bg {
    padding-top: 1.9rem;
    padding-left: 3.1rem;
    padding-bottom: 1.25px;
  }

  .editaddress-right-bg .editaddress-right-section {
    padding-right: 18rem;
  }

  .editaddress-right-bg > div:last-child {
    .MuiButton-containedPrimary {
      margin-right: 17.7rem;
    }
  }
}

// edit address end//

// login/register/forgetpassword start

// login start
.login-breadcrum {
  position: absolute;
  left: 15%;
}

.loginLayout {
  background-color: $color-grey;

  .MuiOutlinedInput-input {
    background-color: $color-white;
  }

  > p {
    color: $color-dark-grey;
  }

  form {
    :nth-child(2) :last-child > .MuiInputAdornment-root > span {
      color: $color-grey-border;
      cursor: pointer;
    }

    :nth-child(3) {
      p {
        padding-top: 5px;
        display: flex;
        align-items: center;
        margin-top: 10px;

        a {
          text-decoration: underline;
        }
      }
    }

    .login-process-button {
      width: 100%;
    }

    .loginBox {
      > p {
        margin-top: 1.5rem;

        a {
          text-decoration: underline;
          margin-left: 0.4rem;
        }
      }
    }

    .margin-tx {
      position: relative;
      margin-top: -2.2rem;
      margin-bottom: 1rem;
      width: 30%;
      background-color: $color-white;
    }

    .centerline {
      margin: 15px;
      border-radius: 5px;
      border: $border-width $color-grey-mild;
      opacity: 1;
    }
  }
}

// login end

.registerLayout {
  background-color: $color-grey;
  .MuiOutlinedInput-input {
    background-color: $color-white;
  }
  > p {
    color: $color-dark-grey;
  }
  form {
    :nth-child(2) > .password-txt > div > div > .MuiInputAdornment-root > span {
      color: $color-grey-border;
      cursor: pointer;
    }

    :nth-child(2) > .verify-password > div > div > .MuiInputAdornment-root > span {
      color: $color-grey-border;
      cursor: pointer;
    }

    :nth-child(4) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      > p {
        width: 80%;
        padding-top: 5px;

        :nth-child(2) {
          text-decoration: underline;
          margin-left: 5px;
          margin-right: 5px;
        }

        :nth-child(4) {
          text-decoration: underline;
          margin-left: 5px;
        }
      }
    }

    .login-process-button {
      width: 100%;
    }

    .text-align-center {
      margin-top: 15px;

      .MuiButton-containedPrimary {
        margin-bottom: 1.8rem;
      }

      .text-decoration {
        text-decoration: underline;
        margin-left: 0.5rem;
      }

      .margin-tx {
        position: relative;
        margin-top: -1.7rem;
        margin-bottom: 1rem;
        width: 30%;
        background-color: $color-white;
      }
    }

    .centerline {
      margin: 15px;
      border-radius: 5px;
      border: $border-width $color-grey-mild;
      opacity: 1;
    }
  }
}

.ForgetpassworLayout {
  background-color: $color-grey;

  > p {
    text-align: center;
    color: $color-grey-border;
    margin-bottom: 2rem;
  }

  form :nth-child(2) > button {
    width: 100%;
  }

  form :nth-child(3) > .MuiGrid-grid-xs-12 > .MuiGrid-direction-xs-column :last-child {
    > button {
      margin-top: 1rem;
      opacity: unset;
      color: black;
      border: $border-width $main-color;
      background-color: transparent;
    }
  }

  form > .MuiGrid-justify-content-xs-center :last-child > .MuiGrid-root > .MuiGrid-item > span > a {
    text-decoration: underline;
  }

  .Forgetresend {
    margin-bottom: 5rem;
  }

  [name="email"] {
    background-color: $color-white;
  }

  [data-testid="button-forgot-password-send-validation-code"] {
    margin-bottom: 1rem;
  }

  .ResetLayout {
    .ResetHeading {
      margin-bottom: 3rem;
    }

    .setPassword {
      margin-top: 6rem;
    }

    :first-child > p {
      color: $color-dark-grey;
      margin-top: 1rem;
    }

    [data-testid="button-reset-password-resend-verification-code"] {
      width: fit-content;
      margin-top: 1rem;
      margin-bottom: 2rem;
      background-color: transparent;
    }

    [name="validationCode"] {
      background-color: $color-white;
    }

    .verifycodebox {
      display: flex;
      flex: row;
      flex-wrap: wrap;

      input {
        height: 3rem;
        border: none;
        // padding-left: 0.5rem;
        font-size: 1.7rem;
        border-bottom: $border-width-2x $color-grey-border;
        margin: 1rem;
        background-color: transparent;
      }

      fieldset {
        border-width: 0;
      }
    }

    .showandhide {
      color: $color-grey-border;
    }

    .verifyPassword {
      div {
        background-color: $color-white;
      }
    }
  }

  .verifycodebutton {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

// login/register/forgetpassword end

//shipping page
.shipping-page-wrap,
.payment-page-wrap {
  .ship-address-list-wrap,
  .payment-address-wrap {
    .addressForm {
      .MuiCheckbox-colorPrimary {
        margin-bottom: 5px;
      }
    }

    .address-list {
      display: flex;
      flex-direction: column;

      .address-card-new {
        border: 0;
        box-shadow: none;
        margin-bottom: 1rem;

        .address-section {
          display: flex;
          align-items: center;

          .address-left {
            width: 80%;
            cursor: pointer;
            padding: 8px;

            p {
              padding-left: 30px;
            }

            .icon-text {
              display: flex;
              padding-left: 0;
            }

            .select-icon {
              margin-right: 0.5rem;
              position: relative;
            }
          }
        }

        &.selected {
          .select-icon::after {
            @include material-icon-filled-include;
            content: "\e86c";
            padding-left: 0px;
            position: absolute;
            left: 0;
          }

          background: $color-grey-dimmer;
        }
      }
    }
  }

  .selected-address-wrap {
    .address-list-shipping {
      display: none;
    }

    .address-card {
      border: 0;
      box-shadow: none;
    }

    hr {
      display: none;
    }

    .MuiCardContent-root {
      padding: 0 1rem;
    }
  }

  .delivery-notes {
    a {
      &::before {
        @include material-icon-include;
        content: "\e145";
        padding-left: 0px;
      }
    }

    textarea {
      width: 100%;
      padding: 0.7rem;
    }
  }

  .disabled-accord.Mui-disabled {
    background: rgba(0, 0, 0, 0.05);
  }
}

//payment page
.payment-page-wrap {
  .credit-card-box {
    border-radius: 6px !important;
    border: 0px !important;

    .cards-img {
      float: right;
      line-height: 1;

      img {
        width: 40px;
        margin-left: 3px;
      }
    }

    .cards-img.paypal-img {
      img {
        width: 25px;
      }
    }

    label.pay-option:nth-child(odd) {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    label.pay-option {
      border: $border-width $color-grey-border;
      padding: 6px 10px;
      border-radius: 5px;
      width: 100%;
      padding-top: 12px;

      &:nth-child(odd) {
        background-color: transparent !important;
      }

      p {
        @include boldfont;
      }

      align-items: flex-start;

      .MuiFormControlLabel-label {
        width: 100%;
      }
    }

    .card-label-box.card-selected-box {
      label.pay-option {
        border-radius: 5px 5px 0 0;
        border-bottom: 0px;
      }
    }

    [role="radiogroup"] {
      .card-label-box {
        &:last-of-type {
          .pay-option {
            border-bottom: 1px solid $color-grey-border;
            border-radius: 5px;
          }
        }
      }
    }

    .card-inner-box {
      border: $border-width $color-grey-border;
      margin: 0px;
      margin-top: -9px;
      border-top: 0px;
      border-radius: 0 0 5px 5px;
    }

    .card-number-text {
      position: relative;

      .current-input-card {
        position: absolute;
        width: 30px;
        top: 44px;
        right: 20px;
      }
    }

    .MuiTextField-root {
      fieldset {
        border-color: rgba(0, 0, 0, 0.23);
        border-width: 1px;
      }
    }
  }

  .cvv-tooltip-wrap {
    position: relative;
    z-index: 1;

    .tooltip-inner {
      position: absolute;
      left: 35px;
      top: -6px;
    }

    .tool-tip-txt {
      span {
        display: inline-block;
        width: 100%;
      }
    }

    .icon {
      font-size: 21px;
    }

    .cvv-text {
      display: none;
      opacity: 0;

      .cvv1 {
        display: inline-block;
        width: 100%;
      }
    }

    .cvv-toolip-btn {
      &:hover + .cvv-text {
        display: block;
        opacity: 1;
        background-color: $color-dark-grey;
        padding: 0.5rem;
        color: $color-white;
        font-size: 0.8rem;
        width: 210px;
        position: absolute;
        left: -90px;
        top: 26px;
        border-radius: 4px;
      }
    }
  }

  .payment-billing-address {
    .billing-inner {
      padding-left: 3rem;
    }
  }

  .view-card-link {
    align-items: center;
  }

  .add-credit-card {
    .icon {
      font-size: 1rem;
    }
  }

  .saved-card-wrap {
    .saved-card-form {
      width: 100%;
    }

    .saved-card-list {
      border: $border-width $color-grey-soft;
      margin-bottom: 0.8rem;
      border-radius: 5px;
      margin-right: 0;
      height: 70px;

      .MuiTypography-root {
        width: 100%;
        display: inline-block;
      }
    }

    .card-info {
      @include fontfamily;
      display: flex;
      align-items: center;
      line-height: 0;
    }

    .card-img,
    .card-no {
      margin-right: 0.6rem;
    }

    .card-img {
      img {
        width: 40px;
      }
    }

    .Left-side {
      width: 12%;
    }

    .right-side {
      width: 88%;
      line-height: 1.5;
      padding-left: 1rem;

      .card-no-text {
        display: block;
      }

      .cvv {
        margin-left: 1.2rem;

        input {
          width: 35px;
          height: 25px;
          padding: 0 12px;
        }

        fieldset {
          border-color: $color-dark-grey;
        }

        .cvv-tooltip-wrap {
          display: inline-block;

          .tooltip-inner {
            top: -16px;
            left: 62px;

            .icon {
              width: 18px;
            }
          }
        }
      }
    }
  }
}

.MuiTooltip-tooltip {
  .cvv1 {
    display: inline-block;
    width: 100%;
  }
}

.shipping-page-wrap,
.payment-billing-address,
.editaddress-right,
.addaddress-right-section {
  [data-testid="country"] {
    width: 100%;
  }
}

.popover-delivery-wrap {
  .popup-link {
    display: inline-flex;
    align-items: center;
  }

  .delv-change {
    margin-left: 5px;
  }
}

.payment-delivery-box {
  .delivery-section {
    padding: 1rem;
    width: 350px;
  }
}

.MuiDialog-container {
  @include fontfamily;

  .MuiFormControlLabel-label {
    @include fontfamily;
  }
}

.MuiDialog-container {
  .MuiDialogContent-root {
    border-top: 0;
  }
}

.verify-address-modal {
  .lbl-verify-addr {
    width: 50%;

    .street,
    .type-res {
      display: inline-block;
      width: 100%;
    }

    .Mui-checked + .MuiFormControlLabel-label {
      font-weight: $font-weight;
    }
  }

  .cancel-btn {
    margin-right: 1rem;
  }

  .original-addr-sec,
  .sugg-addr-sec {
    padding-left: 32px;
  }

  .avs-msg {
    margin-bottom: 1rem;
    color: $color-dark-grey;
  }

  .verify-modal-footer {
    margin-top: 2rem;
  }

  .verify-address-radio {
    width: 100%;
  }
}

.review-page-wrap {
  .card-img-num {
    display: flex;
    margin-top: 10px;
  }

  .MuiPaper-root.Mui-expanded {
    margin-top: 0;
    margin-bottom: 0;
  }

  .review-loader {
    .loader-page {
      .loader-icon {
        width: 90px;
      }
    }
  }
}

//order confirmation
.order-confirm-page-wrap {
  .confirm-order-details {
    dt {
      float: left;
    }

    dd {
      margin-left: 90px;
      margin-bottom: 5px;
    }

    dl {
      margin: 0;
    }

    .order-details {
      .title {
        margin-bottom: 0.5rem;
      }
    }
  }

  .billing-details {
    .card-info > div {
      display: flex;
      align-items: center;

      &.MuiBox-root {
        margin-bottom: 8px;
      }
    }
  }

  .print-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding-right: 0.5rem;
    }
  }

  .confirm-order-items {
    .MuiPaper-root.horizontal-padding-2 {
      padding: 0;
    }

    .order-custom-table {
      box-shadow: none;
    }
  }

  .new-account-Section {
    .create-account {
      .top-margin-5 {
        margin-top: 0;
      }

      .vertical-padding-3 {
        padding-top: 0;
      }

      .first-name,
      .last-name,
      .password-txt,
      .verify-password {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

// product recommendation widget
.product-recomm-widget {
  h4 {
    text-align: center;
  }

  .carousel {
    .product-image-wrap-inner {
      .product-card {
        border: 0;
      }
    }
  }
}

#OrderConfirmationPage {
  .product-recomm-widget {
    .carousel {
      margin: 0;
      height: auto;

      .product-image-wrap-inner {
        width: 99%;
        height: auto;

        .product-card {
          max-width: 100%;
          max-height: inherit;
        }

        .plp-swatch-carousel {
          .show-more {
            margin-left: 0;
          }
        }
      }
    }

    .static-product-carousel {
      .product-image-wrap {
        height: auto;

        .product-card {
          max-height: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

#HomePage {
  .product-recomm-widget {
    .carousel {
      height: 100%;
      margin: 0 1.2rem;

      [aria-label="slide"] {
        padding-bottom: 22.6% !important;
      }
    }

    .product-image-wrap-inner {
      height: auto;

      .product-card {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

//My Account
.account-breadcrum,
.login-breadcrum {
  display: flex;

  .arrow-ico {
    font-size: 1rem;
  }
}

.myaccount-sidebar {
  margin-top: 3rem;
  width: 80%;

  nav {
    li.MuiListItem-root.Mui-selected {
      border-bottom: $border-width-2x $main-color;
      background: transparent;
      color: $main-color;

      span::after {
        @include material-icon-include;
        content: "\e5e1";
        padding-left: 0px;
        position: absolute;
        right: 12px;
        top: 15px;
      }
    }

    .section-link {
      border-bottom: $border-width $color-grey-border;
    }
  }

  a {
    padding: 8px 0;
  }

  li {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

[cid="wish-list-view"] {
  .myaccount-sidebar {
    margin-top: 3rem;
    width: 80%;

    nav {
      [data-testid="account-sidebar-wish-list-link"] {
        border-bottom: $border-width-2x $main-color !important;
        background: transparent;
        color: $main-color;

        span::after {
          @include material-icon-include;
          content: "\e5e1";
          padding-left: 0px;
          position: absolute;
          right: 12px;
          top: 15px;
        }

        li > div > span {
          font-weight: 600;
        }
      }

      .section-link {
        border-bottom: $border-width $color-grey-border;
      }
    }
  }
}

[cid="add-address"] {
  .myaccount-sidebar {
    margin-top: 3rem;
    width: 80%;

    nav {
      [data-testid="account-sidebar-address-book-link"] {
        border-bottom: $border-width-2x $main-color !important;
        background: transparent;
        color: $main-color;

        span::after {
          @include material-icon-include;
          content: "\e5e1";
          padding-left: 0px;
          position: absolute;
          right: 12px;
          top: 15px;
        }

        li > div > span {
          font-weight: 600;
        }
      }

      .section-link {
        border-bottom: $border-width $color-grey-border;
      }
    }
  }
}

[cid="edit-address"] {
  .myaccount-sidebar {
    margin-top: 3rem;
    width: 80%;

    nav {
      [data-testid="account-sidebar-address-book-link"] {
        border-bottom: $border-width-2x $main-color !important;
        background: transparent;
        color: $main-color;

        span::after {
          @include material-icon-include;
          content: "\e5e1";
          padding-left: 0px;
          position: absolute;
          right: 12px;
          top: 15px;
        }

        li > div > span {
          font-weight: 600;
        }
      }

      .section-link {
        border-bottom: $border-width $color-grey-border;
      }
    }
  }
}

.sidebar-account-left {
  .MuiPaper-root {
    box-shadow: none;
  }
}

#my-account-page {
  .account-right-bg {
    background: $color-grey;
    padding: 0.5rem;
  }
}

.myaccount-right-section {
  .personal-info-wrap,
  .recent-order-wrap {
    min-height: 302px;

    .info-title {
      border-bottom: $border-width $color-grey-dimmer;
    }
  }

  .personal-info-wrap {
    .info-title {
      justify-content: space-between;

      a {
        align-items: center;

        .icon {
          font-size: 1.1rem;
        }
      }
    }

    .country-textfield {
      width: 100%;

      label.Mui-disabled {
        color: $main-color;
      }
    }

    .left-side-edit {
      .edit-state {
        display: none;
      }
    }

    .right-side-edit {
      .edit-country {
        display: none;
      }
    }
  }

  .recent-order-table {
    min-height: 170px;

    .MuiPaper-root {
      box-shadow: none;
    }

    .link-ord-history {
      display: none;
    }

    tbody {
      td.pivoted {
        padding: 12px 6px;
        vertical-align: middle;
      }

      tr {
        height: 0vh !important;
      }
    }
  }
}

.recent-order-table {
  .responsiveTable {
    thead {
      display: none;
    }

    tbody {
      tr {
        td:nth-of-type(3) {
          display: none;
        }
      }

      .th-title {
        color: $color-grey-border;
      }
    }
  }
}

// myaccount-wishlist
// 1st page
.wishlist-right-bg {
  background: $color-grey;
  padding: 0.9rem;
}

.sidebar-wishlist-left {
  .MuiPaper-root {
    box-shadow: none;
  }
}

// 2nd page

.sidebar-wishList-left {
  .MuiPaper-root {
    box-shadow: none;
  }
}

.myaccount-wishList {
  .wishliststyledcard {
    [style="position: absolute;"] {
      display: none;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .wishlist-card {
    border: none !important;
  }

  .myaccount-stylecard {
    .wishlist {
      background-color: $color-grey;
    }

    .myaccount-wishlistimage {
      width: 15rem;
      height: 18rem;
      //mix-blend-mode: multiply;
    }
  }

  .cardactionremove .MuiButton-outlinedPrimary::after {
    font-family: "Material Icons Outlined";
    font-weight: 400;
    display: inline-block;
    letter-spacing: normal;
    word-wrap: normal;
    direction: ltr;
    white-space: nowrap;
    font-feature-settings: "liga";
    content: "\f1df";
    padding-left: 15px;
  }

  .MuiButton-outlinedPrimary {
    border: $border-width $main-color;
  }

  .cardactionremove > div:last-child > .MuiButton-textPrimary > span > p {
    display: none;
  }

  .cardactionremove > div:last-child > .MuiButton-textPrimary ::before {
    @include material-icon-include;
    content: "\e92e";
    border: $border-width $main-color;
    padding: 7px;
    font-weight: normal;
  }

  @media (min-width: 1499px) {
    .cardactionremove > div:last-child > .MuiButton-textPrimary ::before {
      font-size: 2rem;
    }

    .cardactionremove .MuiButton-outlinedPrimary::after {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 1366px) {
    .myaccount-stylecard {
      .myaccount-wishlistimage {
        height: 10.5rem;
        width: 14rem;
      }
    }

    .cardactionremove > div:first-child {
      .MuiButton-outlinedPrimary {
        span > p {
          font-size: 8px;
        }
      }
    }

    .cardactionremove > div:last-child > .MuiButton-textPrimary ::before {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1024px) {
    .cardactionremove > div:last-child > .MuiButton-textPrimary ::before {
      font-size: 1.5rem;
    }

    .cardactionremove > div:first-child {
      .MuiButton-outlinedPrimary {
        span > p {
          font-size: 10px;
        }
      }
    }

    .cardactionremove .MuiButton-outlinedPrimary::after {
      font-size: 1rem;
    }
  }
}

.cart-widget {
  .sidebar-cart-left {
    .left-margin-2 {
      margin-left: 0px;
    }
  }

  .cart-Heading {
    margin-bottom: 2rem;
  }

  .cart-table-wpr {
    .emptyCart {
      > div {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      background-color: $color-grey;
      box-shadow: none;
      align-items: center;

      .emptyCartMsg {
        justify-content: center;
        margin-bottom: 0.5rem;
      }
    }

    [data-testid="button-cart-continue-shop"] {
      margin-top: 30px;
    }
  }
}

#CartPage {
  .order-item-table {
    tr {
      border-bottom: 1px solid $color-grey-dimmer;
    }

    tr:nth-child(odd) {
      background: #fff;
    }

    tr:last-child {
      border-bottom: 0px solid $color-grey-dimmer;
    }
  }
}

.not-found {
  margin-top: 3rem;

  .notFound {
    hr {
      border-top: $border-width $color-dark-grey;
      width: 50%;
    }

    .MuiTypography-h1 {
      font-size: 4.5rem;
    }

    h6 {
      padding-bottom: 2rem;
    }

    justify-content: center;
    align-items: center;

    .errorMsg {
      font-size: 12.5rem;
      color: $color-grey-mild;
    }

    .list-makers {
      margin-top: 2rem;

      .custom-list li::marker {
        font-size: 1.5rem;
        color: $color-grey-border;
      }
    }

    [data-testid="button-not-found-continue-shop"] {
      .MuiButton-label {
        margin-right: 5rem;
      }
    }
  }
}

//Button style global
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  .MuiButton-label {
    line-height: 1;
  }

  padding-top: 14px;
  padding-bottom: 9px;

  &::after {
    margin-top: -3px;
  }
}

//loader
.loader-icon-wpr {
  position: fixed;
  top: 40%;
  left: 47%;
  z-index: 1;
}

.loader-page {
  text-align: center;

  .loader-icon {
    width: 125px;
    text-align: center;
  }
}

.cart-widget {
  .cart-table-wpr {
    .loader-page {
      min-width: 60px;
      position: relative;

      .loader-icon {
        width: 60px;
        position: absolute;
        top: -32px;
        background-color: #fff;
        left: 0;
      }
    }
  }
}

.pdp-page-wrap,
.plp-whole-whr {
  min-height: 400px;

  .loader-icon-wpr {
    left: 47%;
  }
}

#HomePage {
  .loader-page {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 47%;
  }

  .product-recomm-widget {
    .loader-page {
      position: static;
    }
  }
}

.app#root {
  & > .loader-page {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 47%;
  }
}

.footer-wrapper {
  .loader-page {
    display: none;
  }
}

//common css end

//small desktop
@media (min-width: 1024px) {
  .ResetLayout {
    .verifycodebox {
      input {
        width: 1.5rem;
      }
    }
  }

  .myaccount-right-section {
    margin-top: 4rem;
  }

  .orderHistory {
    .myaccount-right-section {
      margin-top: 7rem;
    }
  }

  .wishlist-right-section {
    margin-top: 5rem;
  }

  .wishList-right-section {
    margin-top: 5rem;
  }

  .myaddress-right {
    margin-top: 4rem;
  }

  .addaddress-right {
    margin-top: 5rem;
  }

  .editaddress-right {
    margin-top: 5.5rem;
  }

  .cart-table-wpr {
    .emptyCart {
      p {
        font-size: 1.2rem;
        margin-left: 1rem;
        margin-top: 1.1rem;
      }

      .bagIcon {
        font-size: 3rem;
      }
    }
  }

  .notFound {
    hr {
      border-top: $border-width $color-dark-grey;
      width: 50%;
    }

    .list-makers {
      ul {
        li {
          margin-left: 3rem;
          float: left;
        }
      }
    }
  }

  div.app-main-wrapper {
    .header-wrapper-top {
      .header-bg-new {
        .MuiContainer-maxWidthLg {
          max-width: 100%;
          padding: 0px;

          .headerRowOne {
            padding: 0px 40px;

            .header-topbarSection {
              height: 80px;
            }
          }
        }
      }
    }
  }

  .plp-page-title {
    h1 {
      margin-top: 0;
      position: relative;
      top: -17px;
      z-index: -1;
    }
  }

  .MuiContainer-maxWidthLg {
    max-width: 100%;
  }

  //PLP page small desktop
  .plp-sidebar {
    .plp-filter-wrapper {
      width: 100%;

      .MuiAccordionDetails-root {
        padding: 8px 2px 16px;
      }
    }
  }

  .plp-quick-filter,
  .plp-new-arrivals {
    .product-container {
      width: 75%;
    }

    .product-category {
      white-space: nowrap;
    }

    .product-img {
      width: 80%;
    }
  }

  .product-listing-container {
    margin-bottom: 2rem;

    .product-result {
      padding: 0 7px 0 5px;
    }

    .product-wishlist {
      display: block;
    }

    .qty-cart-btn {
      display: flex;
      justify-content: center;

      .custom-qty-input {
        width: 50%;
      }
    }

    .custom-qty-input {
      .qty-inner-box {
        display: inline-flex;
        border: $border-width-2x $main-color;

        .qty-input {
          height: 39px;
          text-align: center;
          border: 0px;
          font-size: 1rem;
          width: 100%;
        }

        button {
          width: 40px;
          border: 0;
          background: transparent;
          color: $color-border;

          &:hover {
            background: $main-color;
            color: $color-white;
            cursor: pointer;
          }
        }
      }
    }
  }

  .plp-page-container {
    position: relative;

    .Hide-SideBar {
      display: none;
    }

    .plp-filter-wrapper {
      margin-top: 73px;

      .plp-product-filter {
        .section-heading {
          display: none;
        }
      }
    }

    .show-Product-full {
      max-width: 100%;
      flex-basis: 100%;
      margin-top: 7px;

      .total-product-shown {
        margin-left: 140px;
      }
    }

    .FilterBtn {
      position: absolute;
      top: 25px;
      z-index: 1;
      left: 7px;

      &::after {
        padding: 0 15px 0 0;
        transform: rotate(180deg);
      }

      &.arrow-right {
        &::after {
          transform: rotate(0deg);
          padding: 0 0 0 15px;
        }
      }
    }

    .plp-show-more {
      margin-top: 5rem;
      margin-bottom: 2rem;
    }
  }

  // .product-image-wrap {
  //   min-height: 460px;
  // }

  .product-image-wrap-inner {
    // transition: height 0.2s;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 48px;
    border: $border-width transparent;
    // transition: 0.2s ease-out;

    .thumbnail-image {
      transition: 0.3s ease-in-out;
      margin-top: 0;
      //mix-blend-mode: multiply;
    }

    &:hover {
      // height: fit-content;
      // position: absolute;
      z-index: 1;
      background: $color-white;
      border: $border-width $color-dark-grey;
      // box-shadow: 0px 1px 5px 4px rgba(0, 0, 0, 0.2);
      // transform: translateY(-5px) translateX(5px);
    }

    &:hover .thumbnail-image {
      transform: scale(1);
      // transform-origin: top;
    }

    &:hover .thumb-image-wrap {
      background: none;
      border: $border-width transparent;
    }

    &:hover .full-height {
      background: $color-white;
    }

    .product-info-section {
      display: none;
      position: relative;
      /*bottom: 5rem;*/
      transition: 0.3s ease-in-out;
      flex-direction: column;
      align-items: center;
      width: 100%;
      opacity: 0;
    }

    &:hover .product-info-section {
      display: block;
      opacity: 1;
      transition: 0.3s ease-in-out;
    }

    .product-info-hover {
      position: absolute;
      left: 0;
      padding-left: 5px;
    }

    .desk-quick-view {
      position: absolute;
      top: -54px;
      opacity: 0.8;
      width: 100%;
    }

    .thumb-image-wrap {
      padding-top: 0.5rem;
    }

    .product-name {
      height: 40px;
      overflow: hidden;
    }

    .product-wishlist {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    .slides-hover {
      // background: $color-white;
      width: 100%;
    }

    .product-sizes {
      border-top: $border-width $color-grey-dimmer;
      border-bottom: $border-width $color-grey-dimmer;

      span {
        padding: 0 0.3rem;

        &.active {
          text-decoration: underline;
        }
      }
    }

    .product-card {
      border: 0;
    }

    .swatchimage {
      width: 15px;
      height: 15px;
    }

    .plp-swatch-carousel {
      position: relative;
      width: 100%;
      margin: 0 auto;

      .swatch-slider {
        display: flex;
        flex-wrap: wrap;
      }

      .swatch-slide {
        width: 13%;
        margin-right: 2px;
      }

      .show-more {
        display: flex;
        align-items: center;
        margin-left: 8px;

        a {
          display: flex;
          font-size: 1rem;
        }
      }

      .selected-img {
        border: $border-width $main-color;
      }

      button {
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 25px;
        width: 25px;
        padding-left: 3px;

        span {
          font-size: 1rem;
        }

        &:disabled {
          pointer-events: none;
        }
      }

      .back-btn {
        left: -32px;
      }

      .next-btn {
        right: -32px;
      }
    }
  }

  //plp end

  .brand-shop-wrapper {
    background-color: $main-color;
    padding: 0px 2rem;
    width: 12%;

    .brand-name-wpr {
      display: flex;
      flex-direction: column;

      .black-btn {
        img {
          width: 90%;
        }

        margin-bottom: 1.5rem;
      }
    }
  }

  .pdp-page-container {
    .pdp-thumbnail-slider {
      button {
        width: 38px;
        height: 38px;
      }
    }
  }

  .payment-page-wrap {
    .payment-billing-address {
      .first-name,
      .last-name,
      .address-two,
      .edit-country,
      .edit-state,
      .city,
      .zip-code,
      .email,
      .phone {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .cart-widget {
    .cart-table-wpr {
      .loader-icon {
        width: 60px;
      }
    }

    .wishlistMobileView {
      display: none;
    }

    .mobile-WishList {
      display: none;
    }

    .mobile-stylecard {
      display: none;
    }
  }

  .order-confirm-page-wrap {
    .confirm-right-section {
      .content-section {
        margin-left: 2rem;
      }
    }
  }
}

//Desktop from 1280px
@media (min-width: 1280px) {
  .plp-page-container {
    .show-Product-full {
      .full-grid-view {
        max-width: 20%;
        flex-basis: 20%;
      }
    }
  }

  .checkout-wrap,
  .cart-widget {
    .cart-rightWpr {
      .odr-summ-sect {
        padding: 30px;
      }
    }
  }
}

//Desktop small to above small
@media (min-width: 1024px) and (max-width: 1365px) {
  //product carousel
  #HomePage {
    .product-recomm-widget {
      .carousel {
        height: 350px;

        [aria-label="slide"] {
          height: 400px;
        }
      }
    }
  }

  #OrderConfirmationPage {
    .product-recomm-widget {
      .carousel {
        [aria-label="slide"] {
          height: 360px;
        }
      }
    }
  }

  .plp-facet-wrap {
    .color-swatches {
      flex: 0 0 49%;
    }
  }

  .payment-page-wrap {
    .payment-billing-address {
      .billing-inner {
        padding-left: 1.5rem;
      }
    }

    .credit-card-box {
      .cards-img img {
        width: 34px;
      }
    }

    .saved-card-wrap {
      .card-img {
        img {
          width: 33px;
        }
      }

      .card-text,
      .card-no,
      .exp-lbl,
      .exp-date {
        font-size: 0.8rem;
      }

      .right-side {
        padding-left: 0.5rem;

        .cvv {
          .cvv-tooltip-wrap {
            .tooltip-inner {
              .icon {
                font-size: 18px;
              }

              left: 46px;
            }
          }

          margin-left: 1rem;

          input {
            width: 30px;
            padding: 0 8px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .order-history-detailspage .MuiDialog-paperScrollPaper {
    width: 85%;
    max-width: 85%;
  }

  #cart_link_2_promocode {
    .MuiButton-label {
      display: none;
    }
  }
}

//Desktop small to big
@media (min-width: 1024px) and (max-width: 1599px) {
  .product-listing-container {
    .product-image-wrap-inner {
      .product-sizes {
        span {
          font-size: 0.7rem;
          padding: 0 0.2rem;
        }
      }
    }

    .plp-swatch-carousel {
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 1600px) {
  .cart-sticky {
    height: 100vh;
  }

  .product-image-wrap-inner {
    padding-bottom: 65px;

    .plp-swatch-carousel {
      .swatch-slide {
        width: 14%;
      }

      .show-more {
        margin-left: 12px;
      }
    }
  }

  .payment-page-wrap {
    .payment-billing-address {
      .first-name,
      .last-name,
      .edit-country,
      .edit-state,
      .city,
      .zip-code,
      .email,
      .phone {
        max-width: 50%;
        flex-basis: 50%;
      }

      .address-two {
        max-width: 100%;
        flex-basis: 100%;
      }

      .phone {
        label {
          margin-bottom: 1.7rem;
        }
      }
    }
  }

  #HomePage {
    .product-recomm-widget {
      .carousel {
        [aria-label="slide"] {
          padding-bottom: 20.6% !important;
        }
      }
    }
  }
}

.account-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

//Mobile screen
@media (max-width: 959px) {
  .ResetLayout {
    .verifycodebox {
      input {
        width: 1rem;
      }
    }
  }

  .login-breadcrum {
    left: 0;
    margin-top: 1rem;
  }

  .cart-table-wpr {
    .emptyCart {
      p {
        margin-left: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .not-found {
    .notFound {
      hr {
        border-top: $border-width $color-dark-grey;
        width: 80%;
      }
    }
  }

  .cart-wishlist {
    .mobile-WishList {
      .wishliststyledcard {
        .mobile-stylecard {
          .cartwishlistData {
            div > .productName > p {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .addremoveaction {
      .cardactionremove {
        margin-left: 0px;
      }
    }
  }

  div.app-main-wrapper {
    .content-wrapper-all {
      margin-top: 3.5rem;
      margin-bottom: 3rem;
    }

    .header-wrapper-top {
      //search bar mobile
      .search-desktop-container {
        .search-toggle-box {
          top: 3.2rem;
        }

        .header-search-bar {
          .search-input.MuiFormControl-root {
            width: 90%;
            margin-top: 3rem;
          }

          .search-icon {
            top: 3.5rem;
          }
        }
      }
    }

    .suggestion-display {
      width: 90%;
    }

    .search-suggested-words {
      width: 100%;
      left: inherit;
    }

    .searh-keyword-hints {
      text-align: left;

      span {
        margin-left: 0;
      }
    }
  }

  .external-site-link {
    display: none;
  }

  body,
  button.MuiButton-root {
    font-size: 0.7rem;
  }

  //product carousel mobile
  .product-widget-carousel {
    .carousel {
      .product-image-wrap-inner {
        width: auto;

        .product-card {
          max-width: inherit;
        }
      }
    }
  }

  .product-widget-carousel {
    .product-card {
      border: 0;
    }
  }

  #HomePage {
    .product-recomm-widget {
      .carousel {
        height: 360px;
      }
    }
  }

  .ribbonImage {
    top: 22px;
    left: 15px;

    img {
      width: 30px;
    }
  }

  //plp mobile
  .plp-breadcrum {
    position: relative;
    margin-top: 0.7rem;
    min-height: 23px;

    ol {
      justify-content: center;
      margin-bottom: 3rem;
    }
  }

  .plp-page-title {
    top: -3rem;
    position: relative;
  }

  .plp-quick-filter-wrap {
    overflow-x: auto;
  }

  .plp-quick-filter {
    display: -webkit-box;
    display: -webkit-inline-box;
    padding-left: 1rem;

    .product-container {
      margin-right: 9%;
    }

    .MuiGrid-grid-xs-2 {
      margin-right: 7%;
    }

    .product-img {
      width: 70px;
    }
  }

  .product-listing-container {
    .product-image-wrap {
      .product-full {
        padding-left: 2px;
        padding-right: 2px;
      }

      .product-wishlist {
        display: block;
        position: absolute;
        right: -5px;
        bottom: 16px;
        border: $border-width $main-color;
        height: 36px;
        padding: 6px;
      }

      .product-info-section {
        display: block;
        order: 1;

        .product-sizes {
          display: none;
        }

        .quick-cart {
          button {
            width: 80%;
          }
        }
      }

      .product-card {
        border: 0;
      }

      .product-full {
        display: flex;
        flex-direction: column;
      }

      .product-footer {
        .product-name {
          height: 34px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .plp-swatch-carousel {
      display: none;
    }

    .plp-mob-sortby {
      position: relative;

      .sort-section {
        position: absolute;
        top: -50px;
        right: 0;

        button {
          padding: 6.5px 12px;
          border-width: 1px;
          width: 170px;
          text-transform: capitalize;
          height: 35px;

          &::after {
            padding-left: 0px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .plp-filter-wrapper {
    width: 40%;
    margin-top: 1rem;

    svg {
      display: none;
    }

    .position-bar {
      position: static;
    }

    .MuiAppBar-colorPrimary {
      border: $border-width $main-color;
      background: $color-white;
      height: 35px;
      line-height: 35px;
      color: $main-color;

      a {
        color: $main-color;
        line-height: 35px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: $main-color;
        display: flex;
        width: 100%;

        &::after {
          @include material-icon-include;
          content: "\e429";
          font-size: 1.2rem;
        }
      }
    }
  }

  .plp-product-filter {
    margin-bottom: 100px;

    .section-title {
      .MuiListItemIcon-root {
        svg {
          display: none;
        }

        &::after {
          @include material-icon-include;
          content: "\e5cd";
          font-size: 1.6rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .plp-facet-wrap {
    margin: 0 1rem;

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .filter-footer-mobile {
      position: absolute;
      bottom: 2rem;
      width: 90%;

      .search-results {
        padding: 1rem 0;
      }
    }

    .filter-category {
      display: flex;
      flex: 0 0 33%;
    }

    .go-link .more,
    .go-link .less {
      margin-left: 0.2rem;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      border-bottom: $border-width $color-grey-soft;
    }

    .MuiAccordionSummary-content {
      font-weight: 700;
      text-transform: uppercase;
    }

    .MuiCollapse-wrapperInner {
      .MuiAccordionDetails-root {
        padding: 8px;
      }
    }
  }

  .plp-sortby-drawer {
    .MuiDrawer-paper {
      border-radius: 20px 20px 0 0;
    }

    .title-sort {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
    }

    .sortby-values {
      li {
        list-style: none;
        padding: 0 1rem 0.5rem 0rem;
        margin: 0 1rem 0.5rem 1rem;
        border-bottom: 1px solid $color-grey-dimmer;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  .plp-show-more {
    margin-top: 2rem;
  }

  .quick-view-modal {
    .product-image {
      .image-wrap {
        img {
          max-height: 300px;
        }
      }
    }
  }

  .apply-filter-btn {
    position: absolute;
    bottom: 0;
  }

  .section-title.section-heading {
    h6 {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .order-addtocart {
    .add-to-cart-wpr {
      .product-img {
        img {
          max-width: 190px;
        }
      }
    }
  }

  //rating  review mobile

  .bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-stars-off,
  .bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-stars-on {
    font-size: 1rem !important;
  }

  .bv-cv2-cleanslate .bv-core-container-211 .bv-inline-rating-container .bv-rating-label {
    font-size: 0.8rem !important;
  }

  //checkout mobile
  .checkout-wrap {
    margin-top: 4rem;
    padding-left: 8px;
    padding-right: 8px;
  }

  //shipping mobile
  .shipping-page-wrap {
    .ship-title-bar {
      .arrow-icon {
        padding-top: 4px;
      }
    }
  }

  .payment-page-wrap {
    .address-list-inner-ob {
      width: 100%;
    }

    .payment-billing-address {
      .billing-inner {
        padding-left: 0;
      }
    }

    .credit-card-box {
      .cards-img {
        img {
          width: 35px;
        }
      }
    }

    .saved-card-wrap {
      .right-side {
        .card-no-text,
        .expiry-cvv {
          font-size: 0.8rem;
        }

        .cvv {
          margin-left: 0.5rem;

          input {
            font-size: 0.7rem;
            width: 25px;
            padding: 0 8px;
          }

          .cvv-tooltip-wrap {
            .tooltip-inner {
              left: 44px;

              .icon {
                font-size: 18px;
              }
            }

            .cvv-toolip-btn {
              &:hover + .cvv-text {
                left: -160px;
              }
            }
          }
        }
      }
    }
  }

  //review page mobile

  .verify-address-modal {
    .lbl-verify-addr {
      width: 100%;
    }
  }

  //My account mobile
  .myaccount-sidebar {
    width: 100%;
  }

  .sidebar-mob-drawer {
    .MuiDrawer-paperAnchorRight {
      width: 100%;
    }
  }

  #my-account-page {
    .sidebar-account-left {
      .MuiAppBar-positionFixed {
        position: relative;
        margin-top: 3rem;
        z-index: 0;
      }
    }
  }

  .orderHistory .sidebar-account-left .MuiAppBar-root,
  .sidebar-address-left .MuiAppBar-positionFixed,
  .sidebar-addaddress-left .MuiAppBar-positionFixed,
  .sidebar-editaddress-left .MuiAppBar-positionFixed,
  .sidebar-wishList-left .MuiAppBar-positionFixed {
    position: relative;
    margin-top: 2rem;
    z-index: 0;
  }

  .myaddress-right {
    margin-top: 0.5rem;
  }

  .addaddress-right {
    margin-top: 0.5rem;
  }

  .editaddress-right {
    margin-top: 0.5rem;
  }

  .sidebar-wishlist-left .MuiAppBar-positionFixed {
    position: relative;
    margin-top: 4rem;
    z-index: 0;
  }

  .cart-widget {
    .wishlist-tabDesign {
      .tab-header {
        .MuiTabs-flexContainer {
          justify-content: space-around;
        }
      }
    }

    .wishlist-tab {
      .MuiBox-root-11 {
        padding: 15px;
      }
    }

    .qty-plus-minus {
      min-height: 45px;

      .loader-page .loader-icon {
        top: -12px;
      }
    }
  }

  .loader-icon-wpr {
    left: 40%;
  }

  .loader-page {
    .loader-icon {
      width: 80px;
    }
  }

  .pdp-page-wrap,
  .plp-whole-whr {
    .loader-icon-wpr {
      left: 40%;
    }
  }

  .velaro-unavailable-launcher,
  .velaro-available-launcher {
    .velaro-custom-launcher-frame {
      bottom: 58px !important;

      iframe {
        bottom: 58px !important;
      }
    }
  }
}

//mobile footer menu
@media (max-width: 959px) {
  .header-wrapper-top {
    bottom: 0;
    top: auto !important;

    .search-desktop-container {
      position: fixed;
      top: 0;

      // margin-top: -3.5rem;
      .search-modal-close {
        bottom: auto !important;
        top: 60px;
      }
    }
  }

  .mobile-header {
    background-color: $main-color;
    z-index: 25;
    position: absolute;
    top: 0;
    width: 100%;

    .mobile-header-branding {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.5rem;

      img {
        height: 22px;
      }
    }
  }

  .mobileHeader {
    background-color: $main-color;
    z-index: 25;
    position: fixed;
    top: 0;
    width: 100%;

    .mobile-header-branding {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.5rem;

      img {
        height: 16px;
      }
    }
  }

  #HEADER_MY_ACCOUNT_Popper {
    top: auto !important;
    left: -14rem !important;
    transform: translate3d(214px, 58px, 0px) !important;
    bottom: 115px;
    right: 0px !important;
    will-change: transform;
    position: fixed;
  }

  .headerRowOne {
    .headerLogo {
      display: none;
    }

    .header-logo {
      width: 100%;

      .header-topbarSection {
        display: flex;
        justify-content: space-around;
      }
    }

    [data-testid="search-bar-mobile-element"] {
      display: flex;
      flex-direction: column;
      margin-bottom: 3px;

      button {
        padding: 0;
      }

      .material-icons-outlined {
        text-align: center;
      }
    }

    .header-topbarSection {
      .icon-text {
        color: $color-white;
        position: relative;
        font-size: 0.7rem;
      }
    }

    .hamburger-menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 7px;

      button {
        padding: 0;
      }

      .material-icons-outlined {
        text-align: center;
      }
    }

    .miniCart {
      padding-top: 0;

      .icon-text {
        text-align: center;
        bottom: 14px;
      }

      display: flex;
      flex-direction: column;
      margin: 0;

      .material-icons-outlined {
        text-align: center;
      }
    }

    .header-signin.padding-5 {
      padding-top: 0;
    }

    .header-signin {
      .icon-text {
        bottom: 8px;
      }
    }

    .AccountPopper {
      padding-top: 0;

      .mobile-AccountPopper {
        margin-bottom: -5px;

        .icon-text {
          bottom: 10px;
        }
      }
    }
  }
}

//Mobile screen small
@media (max-width: 400px) {
  .product-listing-container {
    .product-image-wrap {
      .product-info-section {
        .quick-view-btn {
          button {
            width: 78%;
            padding: 6px 6px;
            font-size: 13px;

            &::after {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 959.95px) {
  .header-topbarSection .header-branding img {
    height: 16px;
  }

  .headerRowOne .header-topbarSection {
    height: 64px !important;
  }

  div.app-main-wrapper .header-wrapper-top .header-bg-new {
    height: 3.5rem;
  }

  .header-menu,
  .header-drawer {
    .MuiDrawer-paperAnchorLeft,
    .MuiDrawer-paperAnchorRight {
      padding-top: 24px;
      padding-right: 10px;
      width: 100% !important;
      max-width: none !important;
    }
  }

  .header-menu {
    .MuiDrawer-paperAnchorRight {
      left: 0;
      right: 0;
    }

    .MuiDrawer-paperAnchorTop {
      bottom: 0px;
      margin-top: 0px !important;
    }

    .mobile-logo {
      img {
        width: 164px;
      }
    }
  }

  .mega-Menu-MobileWpr,
  .menu-container {
    .MuiListItem-root {
      width: 100%;
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .external-site-link-mob {
    width: 100%;
    position: absolute;
    bottom: 10px;
    border-top: 1px solid $color-grey-soft;
    padding: 10px;
    left: 0;

    a {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .mega-mobile-menu-link:first-child {
    display: block;
  }

  .mega-mobile-menu-link {
    .MuiMenuItem-root {
      min-height: 50px !important;
    }
  }

  .MuiDrawer-paperAnchorLeft {
    left: 0;
    right: 0;
  }
}

@media (max-width: 40em) {
  .order-confirm-page-wrap {
    .order-item-table.responsiveTable {
      font-size: 0.7rem;

      .MuiAvatar-root {
        position: absolute;
        left: 0;
        width: 110px;
        height: 110px;
        z-index: 1;
      }

      td.pivoted {
        padding-left: calc(64% + 10px) !important;
      }

      td {
        .tdBefore {
          left: 40%;
        }
      }
    }
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 4px;
  width: 45%;
  height: 100%;
}

.sticky-nav {
  position: fixed;
  left: 0;
}

.pdp-page-container {
  overflow: visible !important;
}

.sticky-absolute {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
}

.cart-rightWpr {
  background-color: $color-grey;

  .card-images {
    img {
      width: 40px;
    }
  }

  .divider-or {
    display: flex;

    .txt-lbl {
      padding: 0 10px;
    }

    &::before,
    &::after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
    }
  }
}

.cart-Heading {
  display: flex;
  align-items: baseline;
}

.cart-detials-accordian {
  height: 200px;
  overflow: auto;
}

//print css style
@media print {
  div.app-main-wrapper {
    .header-wrapper-top {
      position: static;
      display: none;
    }
  }

  .footer-wrapper,
  .carousel,
  .confirm-right-section {
    display: none;
  }

  .order-confirm-page-wrap {
    .confirm-order-details {
      .order-details {
        margin-bottom: 15px;
        flex-grow: 0 !important;
        max-width: 33.333333% !important;
        flex-basis: 33.333333% !important;
      }
    }

    .print-icon {
      display: none;
    }

    .order-item-table.responsiveTable {
      & + div {
        display: none;
      }

      .MuiAvatar-root {
        position: static;
        width: inherit;
        height: inherit;

        img {
          width: 60px;
        }
      }

      td.pivoted {
        padding-left: inherit !important;
      }
    }
  }

  .order-history-detailspage {
    .order-details-col {
      flex-grow: 0 !important;
      max-width: 33% !important;
      flex-basis: 33% !important;
    }

    .order-details-col-last {
      display: none !important;
    }

    .ordtls-hdr-col {
      flex-grow: 0 !important;
      max-width: 25% !important;
      flex-basis: 25% !important;
    }

    .details-product-table {
      .product-img {
        flex-grow: 0 !important;
        max-width: 13% !important;
        flex-basis: 13% !important;
      }

      .product-name {
        flex-grow: 0 !important;
        max-width: 35% !important;
        flex-basis: 35% !important;
      }

      .product-status {
        flex-grow: 0 !important;
        max-width: 50% !important;
        flex-basis: 50% !important;
      }
    }
  }

  .print-hide {
    display: none !important;
  }

  .velaro-unavailable-launcher,
  .velaro-available-launcher {
    .velaro-custom-launcher-frame {
      iframe {
        display: none !important;
      }
    }
  }
}

.myaccount-right-section {
  .order-custom-table {
    padding: 0px !important;
  }
}

.order-history-table {
  .order-listing {
    border-collapse: separate !important;
    background: $color-grey !important;
    padding: 10px;
    border-spacing: 0 12px;

    thead {
      display: none;
    }

    tr,
    tr:nth-child(odd) {
      background-color: $color-white !important;
    }

    tr td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    tr td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 80px;
      vertical-align: middle;
    }
  }
}

.orderhistory-filter {
  height: 45px;

  .MuiAccordionSummary-content {
    .material-icons-outlined {
      position: absolute;
      right: 20px;
    }
  }
}

.tooltip-arrow {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #ffffff;
  margin-top: -16px;
  position: absolute;
  top: 6px;
  right: 3px;
}

.account-mini {
  min-width: 200px !important;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.headerRowOne {
  .header-topbarSection {
    height: 80px !important;
  }
}

.orderhistory-filter-Wpr {
  .MuiCollapse-entered {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.05), 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid #ccc;
    border-top: 0px;
  }

  .orderhistory-filter-heading {
    position: absolute;
    width: 21%;
    border: 1px solid #cccc;
  }
}

.order-history-shadow {
  background-color: #f7f7f7;
  padding: 10px 10px 0px 10px;
  -webkit-box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4);
  margin: 10px 0px;
}

.box-shadow {
  background-color: #f7f7f7;
  padding: 10px 10px 0px 10px;
  -webkit-box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.5) !important;
  -moz-box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.4) !important;
}

.return-jot-form {
  ul.form-section {
    list-style: none;
  }

  .form-line {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 36px;
  }
}

.header-Link {
  display: flex;
  margin: 15px 0px;

  p {
    padding: 0px 15px;
  }
}

.header-Link:before,
.header-Link:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
  color: #ccc;
}

.sign-out-icon {
  font-size: 16px !important;
  padding-left: 5px;
  position: absolute;
  color: #000 !important;
}

.mini-cart-btn {
  width: 175px;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 0px !important;
}

.mini-cart-table {
  box-shadow: none;

  .MuiAvatar-root {
    width: 70px !important;
    height: 70px !important;
  }
}

.mini-cart-table-wpr .MuiPaper-rounded {
  box-shadow: none !important;
}

.mini-cart-popper {
  margin-top: 0px !important;
}

.delivery-stepper {
  margin-left: 40%;

  .Delivery-time {
    position: absolute;
    top: 30px;
    left: -80px;
  }

  .trak-del-date {
    position: relative;
    left: -80px;
    margin-top: 15px;
  }
}

.order-tack-heading {
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  margin: 10px 0;
  padding: 10px 0;
  margin-bottom: 30px;
}

.order-history-trackpopup {
  .MuiDialog-paperScrollPaper {
    max-width: 60%;
    min-width: 50%;
  }
}

.coupon-btn {
  border: 0px !important;
  position: absolute;
  top: 1px;
  right: 2px;
  height: 42px;
  box-sizing: border-box;
  border-left: 1px solid #ccc !important;
  border-radius: 0 3px 3px 0 !important;
}

.coupon-code {
  .close-btn {
    position: relative;
    top: 10px;
  }

  .MuiChip-root {
    border-radius: 16px;
  }
}

.checkout-footer {
  .checkout-footer-heading {
    height: 80px;
    background-color: $color-grey;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    z-index: 1;

    span {
      padding-right: 7px;
    }

    .text-bold {
      border-right: 3px solid #ccc;
      margin-right: 5px;
    }
  }
}

#CheckOutPage {
  margin-top: -3rem;
}

.order-addtocart {
  .MuiDialog-paperScrollPaper {
    width: 650px;
    padding: 15px;
  }
}

.MuiSnackbar-anchorOriginBottomCenter {
  top: 10%;
  position: fixed;
  bottom: auto;
}

.italic {
  font-style: italic;
}

@media (max-width: 40em) {
  .review-page-table {
    .order-item-table.responsiveTable {
      .MuiAvatar-root {
        position: absolute;
        left: 0;
        width: 90px !important;
        height: 90px !important;
        z-index: 1;
      }

      .StyledNumberInput {
        width: 7rem;
      }
    }
  }

  .order-history-detailspage .MuiDialog-paperScrollPaper {
    box-sizing: border-box;
    max-width: 95%;
    padding: 10px;
    width: 95%;
  }
}

.order-steps .MuiStepIcon-root {
  width: 0.9em;
  height: 0.9em;
}

.order-steps .MuiStepIcon-root.Mui-completed {
  color: $color-dark-grey;
  background-color: $color-dark-grey;
  border-radius: 50%;
}

.order-steps .MuiStepIcon-root.Mui-active {
  color: $color-green;
  background-color: $color-green;
  border-radius: 50%;
  border: 1px solid $main-color;

  text {
    display: none;
  }
}

.delivery-stepper {
  .MuiStepIcon-root {
    width: 0.9em;
    height: 0.9em;
  }

  .MuiSvgIcon-root {
    text {
      display: none;
    }
  }
}

@media (max-width: 959.95px) {
  .headerRowOne .header-topbarSection {
    height: 64px !important;
  }

  .header-topbarSection {
    .tooltip-arrow {
      right: 85px;
    }
  }

  .cart-widget .cart-Heading {
    flex-direction: column-reverse;
    padding-top: 13px;
  }

  .pdp-page-container .qty-cart-section {
    .StyledNumberInput {
      width: 12.4rem;
    }

    .cart-whishlist-btn {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      margin-top: 0.8rem;

      .product-add-to-cart {
        margin-left: 0px;
      }
    }
  }

  .order-history-table {
    table.better-pivot > tbody > tr > td.pivoted > .th-title {
      display: none !important;
    }

    table.better-pivot > tbody > tr > td.pivoted > div.tdBefore {
      width: calc(73% - 20px);
    }
  }
}

.MuiButton-containedPrimary:hover.text-dark {
  background-color: rgb(0, 0, 0);
  color: #fff !important;
}

.card-inner-box {
  .MuiFormHelperText-root.Mui-error {
    color: $main-color;
  }
}

.card-inner-box .showRed {
  .MuiFormHelperText-root.Mui-error {
    color: $color-red;
  }
}

.merchandising-link {
  padding-right: 3rem;

  .padding-5p {
    padding: 5px;
    border-right: 2px solid #ccc;
    padding-right: 5px;
    padding-bottom: 0px;
    margin-right: 5px;
  }

  .padding-5p:last-child {
    border-right: 0px solid #ccc;
  }
}

.product-add-to-cart {
  position: relative;

  &:hover .outOfStock {
    display: block;
    opacity: 1;
  }
}

.outOfStock {
  display: none;
  left: 20px;
  color: $color-white;
  background-color: $color-dark-grey;
  text-align: center;
  font-size: 0.8rem;
  padding: 5px 18px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 50px;
  opacity: 0;
  transition: opacity 0.3s;
  transition: 0.5s all;
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
}

.product-widget-carousel {
  .qty-cart-btn {
    text-align: center;
  }
}

.text-dark.MuiButton-containedPrimary,
.MuiButtonBase-root.MuiButton-containedPrimary {
  background-color: $main-color !important;
  color: $color-white !important;
}

.otherClass {
  transform: translateY(0%) translateY(0px);
}

@media (min-width: 959.95px) {
  .pdp-page-container .qty-cart-section .cart-whishlist-btn {
    display: flex;
    align-items: center;
  }

  .pdp-page-container .product-slider .slide-carousel {
    padding-bottom: 60% !important;
  }
}

@media (max-width: 959.95px) {
  .orderhistory-filter-Wpr {
    position: relative;

    .orderhistory-filter-heading {
      width: 97%;
    }
  }

  .order-listing.responsiveTable {
    margin-top: 2rem;
  }

  .order-history-search {
    margin-bottom: 1rem;
  }

  .mobile-padding-1 {
    padding-bottom: 1rem;
  }

  .order-history-trackpopup .MuiDialog-paperScrollPaper {
    max-width: 80%;
    min-width: 80%;
  }

  .form-line {
    .form-input {
      display: flex;
    }
  }

  .pdp-thumbnail-slider {
    max-height: 320px !important;
    margin-top: 10px;
  }
}

.twotireMenu {
  padding-left: 2%;
  border-right: 1px solid $color-grey-soft;

  a {
    padding: 0 2%;
  }
}

.twotireMenu:last-child {
  border-right: 0px solid $color-grey-soft;
}

.borderposition {
  position: absolute;
}

@media (min-width: 1024px) and (max-width: 1919px) {
  div.app-main-wrapper .header-wrapper-top .header-bg-new .expanded-menu-container .megaMenuWpr {
    max-height: 78vh;
    overflow: auto;
  }
}

@media (max-width: 1366px) and (min-width: 980px) {
  .unit-price {
    width: 61px;
  }

  .cart-links-wpr-1 {
    display: none;
  }

  .cart-links-wpr {
    padding-bottom: 18px;
    margin-top: 10px;

    .cart-links {
      position: absolute;
      width: 60%;
    }
  }

  .order-item-table {
    tr {
      position: relative;
    }
  }

  .cart-prod-details {
    position: relative;
    top: -15px;
  }
}

@media (min-width: 1367px) {
  .cart-links-wpr {
    display: none;
  }

  .cart-links-wpr-1 {
    display: block;
  }
}

@media (max-width: 1000px) {
  .cart-links-wpr {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
  .cart-widget {
    padding: 0px;

    .cart-table-wpr {
      .order-custom-table {
        padding: 0px !important;
      }

      .order-item-table.responsiveTable {
        // .tdBefore{display: none;}
        td.pivoted {
          padding-left: calc(23% + 10px) !important;
        }

        td.pivoted:first-child {
          padding: 0px;
        }

        td.pivoted:nth-child(2) {
          .tdBefore {
            display: none;
          }
        }

        tbody tr {
          padding-bottom: 30px;
        }

        .cart-links {
          position: absolute;
          bottom: -175px;
          left: 10px;
          z-index: 1;
          justify-content: flex-start;
        }

        tbody td:nth-child(odd) {
          background: #fff;
        }

        .MuiAvatar-root {
          position: absolute;
          left: 0;
          width: 80px !important;
          height: 90px !important;
          z-index: 1;
        }

        .StyledNumberInput {
          width: 7rem;
        }
      }
    }

    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }

    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      border-bottom: 2px solid #333;
    }

    .responsiveTable tbody tr {
      border: 1px solid #000;
      padding: 0.25em;
    }

    .responsiveTable td.pivoted {
      /* Behave like a "row" */
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;

      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }
  }
}

.orderhistory-filter-Wpr {
  display: none;
}

#sales-menu {
  &:hover {
    .expanded-menu-text {
      color: $color-danger;
    }

    .MuiButton-label {
      border-bottom: 3px solid #000;
      padding-bottom: 9px;
    }
  }

  .MuiButtonBase-root {
    padding-bottom: 0px;
  }

  .expanded-menu-text {
    padding-bottom: 4px;
  }
}

@media (max-width: 1400px) {
  .justify-center-med {
    justify-content: center;
  }
}

@media (min-width: 1920px) {
  .order-history-detailspage .MuiDialog-paperScrollPaper {
    max-width: 62%;
    padding: 30px;
    width: 62%;
  }
}

.order-dtls-hdng {
  position: relative;
  top: -5px;
  width: 99%;
}

.buy-again-btn {
  padding-top: 14px;
  padding-bottom: 12px;
}

@media (max-width: 959.95px) {
  .myaccount-right-section .recent-order-table tbody tr {
    height: auto !important;
  }

  .myaccount-right-section .recent-order-table tbody > tr > td.pivoted > .th-title {
    display: none !important;
  }

  .myaccount-right-section .recent-order-table tbody > tr > td.pivoted > div.tdBefore {
    width: calc(73% - 20px);
  }

  #my-account-page .account-right-bg {
    .recent-order-wrap {
      padding: 0px;
      width: 100%;
      padding-bottom: 20px;
    }
  }

  .myaccount-right-section .recent-order-wrap .info-title {
    h6 {
      padding: 10px 0px 0px 15px;
    }
  }

  .myaccount-right-section .recent-order-wrap {
    min-height: 30px;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
      margin-left: 10px;
    }

    .order-listing.responsiveTable {
      margin-top: 1rem;
    }
  }

  .quick-view-modal {
    .loader-icon-wpr {
      position: fixed;
      top: 40%;
      left: 35%;
    }
  }

  .myaccount-right-section .recent-order-table {
    min-height: 30px;
  }

  #CheckOutPage {
    padding-left: 0px;

    .payment-page-wrap {
      padding: 0px;
    }
  }
}

#cart_input_promocode {
  text-transform: uppercase;
}

.open-zoom-popup {
  .MuiDialog-paper {
    width: 60%;
    max-width: 60%;

    .MuiDialogContent-root {
      text-align: center;

      img {
        max-height: 795px;
      }
    }
  }
}

@media (max-width: 1370px) {
  .open-zoom-popup .MuiDialog-paper .MuiDialogContent-root img {
    max-height: 630px;
  }
}

@media (max-width: 1023px) {
  .open-zoom-popup .MuiDialog-paper {
    width: 90%;
    max-width: 90%;
  }

  .open-zoom-popup .MuiDialog-paper .MuiDialogContent-root img {
    width: 100%;
  }
  .mob-view-all {
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .header-drawer,
  .header-menu .MuiDrawer-paper {
    .close-icon {
      right: 17px;
    }
  }
  .mobile-right-17 {
    right: 25px;
    position: absolute;
  }
}

.image-wrap {
  .border-right {
    border-right: 2px solid #ccc;
    margin-right: 7px;
  }
}

@media (min-width: 1024px) {
  .return-jot-form {
    .MuiDialog-paperWidthSm {
      max-width: 690px;
    }
  }
}
